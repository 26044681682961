import React, { useState, useRef, useEffect} from "react";
import { IoMenu } from "react-icons/io5";
import {
  d_plus_logo,
  home_logo,
} from "../Images/applogos";
import {
  DropdownMenu,
  DropdownToggle,
  Popover,
  PopoverBody,
  Progress,
  Spinner,
  Tooltip,
  UncontrolledDropdown,
} from "reactstrap";
import TalkComponent from "./TalkComponent.jsx";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FaPlusCircle, FaMinusCircle } from "react-icons/fa";
import { fetchAPI } from "../utils/api_helper.jsx";
import { getCookie } from "./Utils";
import useStorageUsage from "./hooks/useStorageUsage.jsx";
import Swal from "sweetalert2";
import { FaDatabase } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { toast, ToastContainer } from "react-toastify";

const Footer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = getCookie("authToken");
  const { storageDetails, loading } = useStorageUsage();
  const [tooltipOpen, setTooltipOpen] = useState({});

  const toggleTooltip = (index) => {
    setTooltipOpen({
      ...tooltipOpen,
      [index]: !tooltipOpen[index],
    });
  };
  const [authenticated, setAuthenticated] = useState(false);

  useEffect(() => {
    const isAuthenticated = getCookie("authToken");
    setAuthenticated(isAuthenticated);
  }, []);

  const [footerApps, setFooterApps] = useState([]);
  const popoverRef = useRef();
  const popoverTargetRef = useRef();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loadingApp, setLoadingApp] = useState(null);

  const toggleImageInFooter = async (image, key, value) => {
    setLoadingApp(key);
    if (!authenticated) {
      toast.error("Please login first");
      setLoadingApp(null);
      return;
    }
    const Data = new FormData();
    Data.append("columnValue", value);

    try {
      Data.append("action", "update");
      Data.append("column", "apps");
      Data.append("columnKey", key);
      const addApps = await fetchAPI(Data, "api/v1/public/frontend-settings");
      if (addApps.status) {
        setFooterApps((prevFooterApps) =>
          prevFooterApps.map((app) => (app.key === key ? { ...app, footer_visibility: value } : app))
        );
      } else {
        console.log("error");
      }
    } catch (error) {
      console.error("Error updating footer app visibility:", error);
    } finally {
      setLoadingApp(null);
    }
  };
  useEffect(() => {
    fetchFooterApps();
  }, []);

  const fetchFooterApps = async () => {
    try {
      const Data = new FormData();
      Data.append("action", "fetch");
      const response = await fetchAPI(Data, "api/v1/public/frontend-settings");
      if (response.status) {
        setFooterApps(
          response?.data?.frontend_settings?.apps.map((app) => ({
            ...app,
            imgSrc: `https://api.silocloud.io/${app.imgSrc}`, // Directly use the URL here
            link:
              app.title === "Talk"
                ? `${app.link}/token-login/${authToken}`
                : `${app.link}`,
          }))
        );
        //       ? { ...app, footer_visibility: tempApp.footer_visibility }
    
      }
    } catch (error) {
      console.error("Error fetching footer apps:", error);
    }
  };

  // eslint-disable-next-line
  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const handleClickOutside = (event) => {
    if (
      popoverRef.current &&
      !popoverRef.current.contains(event.target) &&
      popoverTargetRef.current &&
      !popoverTargetRef.current.contains(event.target) &&
      !event.target.closest(".footerlogosPop-container")
    ) {
      setPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [modal, setModal] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const toggle = () => setModal(!modal);

  const togglePopover = () => setPopoverOpen(!popoverOpen);

  const handleClick = async (data) => {
    try {
      const response = await fetch("", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();
      console.log("Data saved successfully:", result);
    } catch (error) {
      console.error("Error saving data:", error);
    }

    Swal.fire({
      position: "center-center",
      icon: "success",
      title: "Your file has been Uploaded.",
      showConfirmButton: false,
      timer: 1500,
    });
  };

  return (
    <>
    <ToastContainer/>
      {process.env.REACT_APP_API_URL !== "https://api.axiomwebcontrol.com/" ? (
        <section className="section-footer w-100 position-fixed bottom-0 start-0 z-3 ">
          <div className="container-fluid border-top">
            <div className="row justify-content-between align-items-center py-2">
              <div className="col-2 col-lg-3">
                <div className="d-flex align-items-center justify-content-start">
                  <Link to="https://silocloud.io/">
                    <img
                      src={home_logo}
                      alt="homeicon"
                      className="homeicon"
                      height={50}
                    />
                  </Link>
                  <div className="storage-block d-none d-lg-block rounded-3 px-2 py-1 ms-1">
                    <Link to="https://storage.silocloud.io/">
                      <div className="d-flex align-items-baseline mb-1">
                        <FaDatabase className="storageicon" />
                        <p className="fs-9px mb-0 ms-1 opacity-75">
                          Cloud Storage {storageDetails.usedStorageText}/
                          {storageDetails.planStorageText} Used
                        </p>
                      </div>
                      <div>
                        <Progress
                          value={storageDetails.percentageUsed}
                          color="info"
                          className="progress-storage"
                        />
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-9 col-lg-7 my-auto responsive-mobile">
              <div className="d-flex justify-content-center align-items-center">
                {footerApps.map((image, index) => {
                  let tempfooterApp = image.footer_visibility == "1" || !image.popOver;

                  return (
                    tempfooterApp && (
                      <div
                        key={index}
                        className="text-center me-1 me-lg-2 pointer"
                        onClick={() => {
                          if (image.useNavigate) {
                            navigate("/apps");
                          } else {
                            window.location.href = image.link;
                          }
                        }}
                      >
                        {!image.imgSrc ? (
                          <Spinner size="sm" className="mt-1 spinner-md" />
                        ) : (
                          <div
                            className={`position-relative footer-apps ${popoverOpen ? "footerlogosPop-container" : ""}`}
                          >
                            <img
                              src={image.imgSrc}
                              id={`footer-logo-${index}`}
                              alt={`Footer Logo ${index}`}
                              className="footerlogos"
                              height={image.title === "Mail" ? "24px" : "28px"}
                            />
                            {image.showIcon ? (
                              <div
                                className={`plus-minus-icon-${image.key} ${popoverOpen ? "minus-icon" : ""}`}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  toggleImageInFooter(image, image.key, "2");
                                }}
                              >
                                <FaMinusCircle className="plus-minus-icon" />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}

                        <Tooltip
                          placement="top"
                          isOpen={tooltipOpen[index]}
                          target={`footer-logo-${index}`}
                          toggle={() => toggleTooltip(index)}
                        >
                          {image.title}
                        </Tooltip>
                      </div>
                    )
                  );
                })}

                <div className="bg-icon-plus ms-1" ref={popoverTargetRef}>
                  <img src={d_plus_logo} alt="" height="15" id="PopoverTop" onClick={togglePopover} />
                </div>

                <Popover
                  placement="top"
                  isOpen={popoverOpen}
                  target="PopoverTop"
                  toggle={togglePopover}
                  innerRef={popoverRef}
                  className="popover-images"
                >
                  <PopoverBody>
                    {footerApps.length === 0 ||
                    footerApps.every((image) => image.popOver || image.footer_visibility == "2") ? (
                      <div className="text-center">
                        <img src={d_plus_logo} alt="default" height="28px" />
                        <p className="fs-9px mt-1 opacity-75 mb-0">Apps</p>
                      </div>
                    ) : (
                      footerApps.map((image, index) => {
                        return (
                          image.popOver &&
                          image.footer_visibility == "2" && (
                            <div
                              key={index}
                              className="footerlogosPop-container text-center pointer"
                              onClick={() => {
                                toggleImageInFooter(image, image.key, 1);
                              }}
                            >
                              {loadingApp === image.key ? (
                                <Spinner size="sm" className="mb-2 spinner-md" />
                              ) : (
                                <>
                                  <div className="position-relative footer-apps">
                                    <img
                                      src={image.imgSrc}
                                      alt={`Popover Logo ${index}`}
                                      className="footerlogosPop"
                                      style={image.style}
                                      height={image.title === "Mail" ? "22px" : "28px"}
                                    />
                                    <div className={` plus-minus-icon-${image.key}`}>
                                      <FaPlusCircle
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleImageInFooter(image, image.key, 1);
                                        }}
                                        className="plus-minus-icon"
                                      />
                                    </div>
                                  </div>
                                  <p className="fs-9px mt-1 opacity-75 mb-0">{image.title}</p>
                                </>
                              )}
                            </div>
                          )
                        );
                      })
                    )}
                  </PopoverBody>
                </Popover>
              </div>
            </div>
              <div className="col-1 col-lg-2 px-0 px-lg-3 mobile-view  my-auto">
                <div className="d-lg-none ">
                  <UncontrolledDropdown
                    direction="up"
                    className="d-flex justify-content-center align-items-center"
                  >
                    <DropdownToggle
                      className="btn-action p-0  border-0 bg-transparent pe-3 pb-1 "
                      color="primary"
                    >
                      <span>
                        <IoMenu
                          name="menu"
                          className="theme-menu"
                          style={{
                            color: "#5c5c5c",
                          }}
                        />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu
                      style={{
                        backgroundColor: "#fff",
                        color: "#5c5c5c",
                      }}
                    >
                      <div className="link-list-opt  ">
                        <li className="py-2 m-2 border-bottom">
                          {/* <TalkComponent /> */}
                        </li>
                        <li className="">
                          <div className="d-flex align-items-center footer-bottom  m-2 border-bottom justify-content-center ">
                            <a
                              href="https://silocloud.com/"
                              className="mb-0 fs-9px  RFootertext"
                              style={{
                                color: "#5c5c5c",
                              }}
                            >
                              About
                            </a>
                            <a
                              href="https://silocloud.io/privacy-policy"
                              className="mb-0 fs-9px RFootertext"
                              style={{
                                color: "#5c5c5c",
                              }}
                            >
                              Privacy
                            </a>
                            <a
                              href="https://silocloud.io/terms-condition"
                              className="mb-0 fs-9px RFootertext"
                              style={{
                                color: "#5c5c5c",
                              }}
                            >
                              Terms
                            </a>
                          </div>
                        </li>
                        <li className="py-2">
                          <div className="storage-block rounded-3 px-2 py-1 ms-1">
                            <a href="https://storage.silocloud.io/">
                              <div
                                className="d-flex align-items-center  mb-1"
                                style={{
                                  color: "#5c5c5c",
                                }}
                              >
                                <FaDatabase name="db-fill" className="fs-5" />
                                {!loading ? (
                                  <p className="fs-9px mb-0 ms-1 opacity-75">
                                    Cloud Storage{" "}
                                    {storageDetails.usedStorageText}/
                                    {storageDetails.planStorageText} Used
                                  </p>
                                ) : (
                                  <Skeleton
                                    width={100}
                                    height={8}
                                    baseColor="#c5bfe0"
                                  />
                                )}
                              </div>
                              <div className="">
                                <Progress
                                  value={storageDetails.percentageUsed}
                                  color="info"
                                  className="progress-storage"
                                />
                              </div>
                            </a>
                          </div>
                        </li>
                      </div>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
                <div className="d-none d-lg-block">
                  {/* <TalkComponent /> */}
                  <div className="d-flex align-items-center footer-bottom justify-content-center justify-content-lg-end">
                    <a
                      href="https://silocloud.com/"
                      className="mb-0 fs-9px pr-1"
                    >
                      About
                    </a>
                    <a
                      href="https://silocloud.io/privacy-policy"
                      className="mb-0 fs-9px pr-1"
                    >
                      Privacy
                    </a>
                    <a
                      href="https://silocloud.io/terms-condition"
                      className="mb-0 fs-9px pr-1"
                    >
                      Terms
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        ""
      )}
    </>
  );
};

export default Footer;
