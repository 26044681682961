import React, { useState, useEffect } from "react";
import { getCookie } from "./Utils";
import { useNavigate } from "react-router-dom";

export default function Publications({ count, setCount }) {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  // const [count, setCount] = useState(0);
  useEffect(() => {
    // if (data) {
    fetchRecentData();
    // }
  }, []);

  const fetchRecentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-publication`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.list);
      setCount(responseData?.data.allPublicationsCount);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const  navigateToView = (id) => {
    navigate(`/viewpublisher/${id}`);
  };

  return (
    <>
      <div className="container-fluid profile-container">
        <div className="row">
          {data
            ? data.map((item, idx) => (
                <div className="col-md-2" >
                  <div className="card w-100" onClick={() => navigateToView(item.id)}>
                    <img src={item?.thumbnail} class="card-img-top" alt="..." />
                  </div>
                  <div className="publicationstext pt-2">{item?.title}</div>
                  <div className="publicationsdate">{item.created_at}</div>
                </div>
              ))
            : ""}
        </div>
      </div>
    </>
  );
}
