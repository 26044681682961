import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { getCookie } from "./Utils";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import {
  FaChevronLeft,
  FaChevronRight,
  FaCompress,
  FaExpand,
} from "react-icons/fa6";

import {
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaPause,
  FaPlay,
  FaSearchMinus,
  FaSearchPlus,
  FaTh,
  FaVolumeMute,
  FaVolumeUp,
} from "react-icons/fa";
import { Document, Page, pdfjs } from "react-pdf";
import PageFlip from "react-pageflip";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import { useParams } from "react-router-dom";
import flipSound from "../Sound/page-flip.ogg";
import profileimage from "../Images/profile.png";
import postimage from "../Images/social.png";
import postimage1 from "../Images/socialpost.png";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://unpkg.com/pdfjs-dist@latest/es5/build/pdf.worker.min.js";

export default function ViewPublisher() {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null);
  const [pages, setPages] = useState([]);
  const [isMuted, setIsMuted] = useState(false);
  const [data, setData] = useState();
  const [zoomLevel, setZoomLevel] = useState(1);
  const [loading, setLoading] = useState(false);
  const audioRef = useRef(null);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const pageFlipRef = useRef(null);
  const [show, setShow] = useState(false);
  const [pdfUrl, setpdfUrl] = useState(null);
  const [audio] = useState(new Audio(flipSound));
  const { pdf_id } = useParams();

  const flipIntervalRef = useRef(null);
  const bookRef = useRef(null);

  useEffect(() => {
    fetchRecentData();
  }, []);

  const fetchRecentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-publication`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const navigateToView = (id) => {
    navigate(`/viewpublisher/${id}`);
  };

  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));

  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));

  const toggleMute = () => setIsMuted(!isMuted);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);

    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const handlePrevPage = () => {
    if (pageFlipRef.current) {
      pageFlipRef.current.pageFlip().flipPrev();
    }
  };

  const handleNextPage = () => {
    if (pageFlipRef.current) {
      pageFlipRef.current.pageFlip().flipNext();
    }
  };

  const handleFirstPage = () => {
    if (pageFlipRef.current) {
      pageFlipRef.current.pageFlip().turnToPage(0);
    }
  };

  const handleLastPage = () => {
    if (pageFlipRef.current) {
      const totalPages = pageFlipRef.current.pageFlip().getPageCount();
      pageFlipRef.current.pageFlip().turnToPage(totalPages - 1);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying((prevState) => !prevState);
    if (!isPlaying) {
      // Start auto flipping
      flipIntervalRef.current = setInterval(() => {
        if (
          pageFlipRef.current.pageFlip().getCurrentPageIndex() <
          numPages - 1
        ) {
          pageFlipRef.current.pageFlip().flipNext();
        } else {
          clearInterval(flipIntervalRef.current); // Stop at the last page
          setIsPlaying(false);
        }
      }, 1000); // Flip every 2 seconds
    } else {
      // Stop auto flipping
      clearInterval(flipIntervalRef.current);
    }
  };

  useEffect(() => {
    fetchDatafolder();
    fetchApiPdf();
  }, []);

  useEffect(() => {
    const loadPages = async () => {
      const pagesArray = [];
      for (let i = 1; i <= numPages; i++) {
        const canvas = await document.querySelector(
          `.demoPage canvas:nth-child(${i})`
        );
        pagesArray.push(canvas.toDataURL("image/jpeg"));
      }
      setPages(pagesArray);
    };
    if (numPages) loadPages();
  }, [numPages]);

  // const { pdf_id } = useParams();

  const handleFlip = () => {
    if (!isMuted) {
      audio.play();
    }
  };

  const fetchDatafolder = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-flipbooks`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchApiPdf = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/get-tempurl?pdf_id=${pdf_id}`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      const responseData = await response.json();
      // const newdata = await responseData.data.pdf_list[0].pdf_file;
      const newdata = await responseData.data.pdf_file_url;

      setpdfUrl(newdata);
    } catch (error) {
      console.error(error);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setShow(true);
  };

  const pdfData = useSelector((state) => state.pdfUrl.pdfUrl);

  useEffect(() => {
    const handleFlip = () => {
      if (audioRef.current && !isMuted) {
        audioRef.current.play();
      }
    };

    if (bookRef.current) {
      bookRef.current.pageFlip().on("flip", handleFlip);
    }

    return () => {
      if (bookRef.current) {
        bookRef.current.pageFlip().off("flip", handleFlip);
      }
    };
  }, [isMuted]);

  useEffect(() => {
    const data = pdfData.dataUri;

    setFileData(data);
    console.log(data);

    if (data && data.startsWith("data:application/pdf")) {
      const base64 = data.split(",")[1]; // Remove the prefix
      const pdfData = atob(base64);
      const loadingTask = pdfjsLib.getDocument({ data: pdfData });
      loadingTask.promise
        .then((pdf) => {
          const numPages = pdf.numPages;
          const loadedPages = [];
          const renderPage = (pageNum) => {
            pdf.getPage(pageNum).then((page) => {
              const viewport = page.getViewport({ scale: 1.5 });
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
              page.render(renderContext).promise.then(() => {
                loadedPages.push(canvas.toDataURL());
                if (loadedPages.length === numPages) {
                  setPages(loadedPages);
                  setIsLoading(false); //shimmer before display the flipbook
                } else {
                  renderPage(pageNum + 1);
                }
              });
            });
          };
          renderPage(1);
        })
        .catch((error) => {
          console.error("Error loading PDF:", error);
        });
    } else {
      console.error("Invalid or missing PDF data");
    }

    return () => {
      if (bookRef.current) {
        bookRef.current.pageFlip().off("flip", handleFlip);
      }
    };
  }, [navigate]);

  // Upload Publisher -API
  const publishClick = async () => {
    const toastId = toast.loading("Publishing...");

    try {
      const base64 = fileData.split(",")[1];
      const formData = new FormData();
      // formData.append("pdf_file", base64ToBlob(base64));
      formData.append("title", input);
      formData.append("flipbook_id", pdf_id);
      formData.append("visibility", 2);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/publish`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );

      if (!response.ok) {
        console.log(response);
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      const pdfs = responseData?.data?.fileData || [];
      console.log(pdfs);
      setData(pdfs);

      toast.update(toastId, {
        render: "Publication successful!",
        type: "success",
        isLoading: false,
        autoClose: 3000,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.update(toastId, {
        render: "Publication failed. Please try again.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    }
  };

  function base64ToBlob(base64, contentType = "", sliceSize = 512) {
    try {
      const byteCharacters = atob(base64);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      return new Blob(byteArrays, { type: contentType });
    } catch (error) {
      console.error("Error converting base64 to Blob:", error);
      return null;
    }
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center mt-5">
        {
          <>
            <div className="book-container">
              <div className="book">
                <div className="page page-left">
                  <div className="shimmer"></div>
                </div>
                <div className="page page-right">
                  <div className="shimmer"></div>
                </div>
              </div>
            </div>
          </>
        }
      </div>
    );
  }
  return (
    <>
      <div
        className="container-fluid viewheight my-2"
        style={{ overflow: "hidden" }}
      >
        <div className="publish-flipbook-container py-3">
            <div
              style={{ transform: `scale(${zoomLevel})`, position: "relative" }}
            >
              <Document
                file={pdfUrl}
                onLoadSuccess={onDocumentLoadSuccess}
                className="flipbook-wrapper3"
              >
                {numPages && (
                  <PageFlip
                    ref={pageFlipRef}
                    width={400}
                    height={500}
                    flipOnTouch
                    onFlip={handleFlip}
                    showCover={true}
                  >
                    {[...Array(numPages).keys()].map((pageIndex) => (
                      <div key={pageIndex} className="demoPage">
                        <Page pageNumber={pageIndex + 1} width={400} />
                      </div>
                    ))}
                  </PageFlip>
                )}
              </Document>
            </div>
     
          <div className="toolbox3 d-flex justify-content-center">
            <div className="tool3 text-center" onClick={handleFirstPage}>
              <FaAngleDoubleLeft />
            </div>
            <div
              className="tool3 text-center"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </div>
            <div
              className="tool3 text-center"
              onClick={handleNextPage}
              disabled={currentPage === numPages}
            >
              <FaChevronRight />
            </div>
            <div className="tool3 text-center" onClick={handleLastPage}>
              <FaAngleDoubleRight />
            </div>

            <div className="tool3 text-center" onClick={zoomIn}>
              <FaSearchPlus />
            </div>
            <div className="tool3 text-center" onClick={zoomOut}>
              <FaSearchMinus />
            </div>
            <div className="tool3 text-center" onClick={togglePlayPause}>
              {isPlaying ? <FaPause /> : <FaPlay />}
            </div>
            <div className="tool3 text-center" onClick={toggleMute}>
              {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            </div>
            <div className="tool3 text-center" onClick={toggleFullScreen}>
              {isFullScreen ? <FaCompress /> : <FaExpand />}
            </div>
          </div>
        </div>
        <div className="container-fluid pt-5">
          <h4>BRD</h4>
          <div className="publicationsdate">Published On 18 Sept</div>
        </div>

        <hr className="px-5" />

        <div className="profile-pic d-flex justify-content-start container-fluid">
          <div className="profilecircle">
            <img
              src={getCookie("profile_pic")}
              // src={profileimage}
              alt=""
              className="roundedcircle1"
              width={40}
            />
          </div>
          <div className="username d-flex align-items-center fs-5 fw-bold">
            {getCookie("username")}
          </div>
        </div>

        <div className="container-fluid py-5">
          <div className="container-fluid">
            <p className="morefrom">
              {" "}
              More from <span>{getCookie("username")}</span>
            </p>
          </div>
          <div className="container-fluid profile-container">
            <div className="row">
              {data
                ? data.map((item, idx) => (
                    <div className="col-md-2">
                      <div
                        className="card w-100"
                        onClick={() => navigateToView(item.id)}
                      >
                        <img
                          src={item?.thumbnail}
                          class="card-img-top"
                          alt="..."
                        />
                      </div>
                      <div className="publicationstext pt-2">{item?.title}</div>
                      <div className="publicationsdate">{item.created_at}</div>
                    </div>
                  ))
                : ""}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
