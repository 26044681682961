import React, { useState, useRef, useEffect } from "react";
import { pdfjs } from "react-pdf";
import pdf from "../Images/Silo_Dropzone.png";
import pdf1 from "../Images/Axiom_Dropzone.png";

import { useNavigate, Link } from "react-router-dom";
import { FaRegFolder } from "react-icons/fa6";
import { FiUpload } from "react-icons/fi";
import { openDB, addItem } from "./IndexedDB";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch } from "react-redux";
import { addFilePath } from "./redux/slice/pdfUrlSlice";
import { calcPercentage } from "./Utils";
import Flipbook from "./Flipbook";
import Flipbook2 from "./Flipbook2";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;

function Dropdown() {
  const [showUpload, setShowUpload] = useState(false);
  const [filedata, setFile] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const dropAreaRef = useRef(null);
  const dispatch = useDispatch();
  const MAX_SIZE = 200 * 1024 * 1024;

  const handleFileUpload = async (file) => {
    console.log(file);

    if (file) {
      console.log(file.size);

      if (file.size > MAX_SIZE) {
        toast.error(
          "File size exceeds the 200 MB limit. Please upload a smaller file."
        );
        return;
      }

      const db = await openDB();

      // Convert file to base64 with data URI
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const dataUri = reader.result; // Get the data URI
          const fileId = await addItem(db, { file });
          dispatch(addFilePath({ dataUri, fileId }));
          setShowUpload(true);
          // navigate("/flipbook");
        } catch (e) {
          // toast.error("Error storing the file. Please try again.");
        }
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }

    setFile(file);
    setShowUpload(true);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    setFile(file);
    event.target.value = null;
    if (!file) return;
    const MAX_SIZE = 200 * 1024 * 1024;
    if (file.size > MAX_SIZE) {
      toast.error(
        "File size exceeds the 200 MB limit. Please upload a smaller file.",
        {
          position: "top-right",
          autoClose: 4000,
          hideProgressBar: false,
          top: 50,
        }
      );
      return;
    }
    setShowUpload(true);
    localStorage.setItem("Filename", file.name);
    handleFileUpload(file);
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropAreaRef.current.classList.add("drag-over");
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropAreaRef.current.classList.remove("drag-over");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropAreaRef.current.classList.remove("drag-over");
    const file = event.dataTransfer.files[0];
    handleFileUpload(file);
  };

  useEffect(() => {
    const dropArea = dropAreaRef.current;
    dropArea.addEventListener("dragover", handleDragOver);
    dropArea.addEventListener("dragleave", handleDragLeave);
    dropArea.addEventListener("drop", handleDrop);
    return () => {
      dropArea.removeEventListener("dragover", handleDragOver);
      dropArea.removeEventListener("dragleave", handleDragLeave);
      dropArea.removeEventListener("drop", handleDrop);
    };
  }, []);
  if (showUpload === true) {
    return <Flipbook2 fileDataFolder={filedata} />;
  }
  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
      <div className="dropdown-container">
        <div className="d-flex justify-content-md-end justify-content-center p-3">
          {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
            <div className="px-2">
              <Link to="/collection">
                <button
                  type="button"
                  className="btn btn-danger btnsize d-flex align-items-center"
                >
                  <FaRegFolder className="btnlogo" />
                  <span className="btntext">Collection</span>
                </button>
              </Link>
            </div>
          ) : (
            <div className="px-2">
              <Link to="/collection">
                <button
                  type="button"
                  className="btn gradient-button btnsizeaxiom d-flex align-items-center"
                >
                  <FaRegFolder className="btnlogo" />
                  <span className="btntext">Collection</span>
                </button>
              </Link>
            </div>
          )}
          {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
            <div className="px-2">
              <Link to="/">
                <button
                  type="button"
                  className="btn btn-outline-danger backbtn backbtntext"
                >
                  Back
                </button>
              </Link>
            </div>
          ) : (
            <div className="px-2">
              <Link to="/">
                <button
                  type="button"
                  className="btn btntext gradient-button  btnsizeaxiom px-4"
                >
                  Back
                </button>
              </Link>
            </div>
          )}
        </div>
        <div className="dropdown-inner">
          <p className="heading text-center my-md-3 customfont">
            Upload a file to create a flipbook
          </p>
          <div
            ref={dropAreaRef}
            className="dropzone container p-lg-3 p-2 mt-md-4 drop-area w-50"
            onClick={triggerFileUpload}
          >
            <div className="d-flex justify-content-center">
              {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
                <img
                  src={pdf}
                  alt="Upload PDF"
                  style={{ cursor: "pointer" }}
                  className="py-2 img-fluid dropdownimg"
                />
              ) : (
                <img
                  src={pdf1}
                  alt="Upload PDF"
                  style={{ cursor: "pointer" }}
                  className="py-2 img-fluid dropdownimg"
                />
              )}
              <input
                id="file-upload"
                type="file"
                accept="application/pdf"
                onChange={(e) => handleFileInputChange(e)}
                style={{ display: "none" }}
                ref={fileInputRef}
              />
            </div>
            <div className="dragdroptext text-center">Drag & Drop</div>
            <div className="ortext text-center py-1">OR</div>
            {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
              <div className="d-flex justify-content-center py-1">
                <button
                  type="button"
                  className="btn btn-danger  btnsize d-flex align-items-center"
                >
                  <FiUpload className="btnlogo" />
                  <span className="text-light btntext">Upload File</span>
                </button>
              </div>
            ) : (
              <div className="d-flex justify-content-center py-1">
                <button className="btn gradient-button btnsizeaxiom d-flex align-items-center">
                  <FiUpload className="btnlogo" />
                  <span className="btntext">Upload File</span>
                </button>
              </div>
            )}
          </div>
          <div className="d-flex justify-content-center filesize">
            *Recommented file size 20mb
          </div>
        </div>
      </div>
    </>
  );
}
export default Dropdown;
