import React from 'react'
import noresultfound from "../Images/NoResultFound.png"

export default function Followers() {
  return (
    <div className="followers-container"> 
    <div className='d-flex justify-content-center'>
      <img src={noresultfound} alt="" height={200} />
    </div>
    </div>
  )
}
