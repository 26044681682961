import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  pdfUrl: {
    dataUri: {},
    fileId: {},
  },
};

const pdfUrlSlice = createSlice({
  name: "pdfUrl",
  initialState,
  reducers: {
    addFilePath: (state, action) => {
      const { dataUri, fileId } = action.payload;
      state.pdfUrl.dataUri = dataUri;
      state.pdfUrl.fileId = fileId;
    },
  },
});

export const { addFilePath } = pdfUrlSlice.actions;
export default pdfUrlSlice.reducer;
