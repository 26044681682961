
import React from 'react';


const ShimmerEffect = () => {
  return (
    <div className="shimmer-wrapper">
      <div className="shimmer"></div>
      <div className="shimmer-content">
        <div className="shimmer-block"></div>
        <div className="shimmer-block"></div>
        <div className="shimmer-block"></div>
      </div>
    </div>
  );
};

export default ShimmerEffect;
