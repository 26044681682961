import { getCookie } from "../Utils";

export const fetchCollectionAndContent = async (search = "") => {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/flipbook/search?${search}`,
    {
      method: "GET",
      headers: {
        authToken: getCookie("authToken"),
      },
    }
  );
  return await response.json();
};
export const fetchPdfById = async (flipbook_id = "") => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-flipbooks?flipbook_id=${flipbook_id}`,
      {
        method: "GET",
        headers: {
          authToken: getCookie("authToken"),
        },
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    throw new Error("Something went wrong");
  }
};
export const sellFlipBook = async (flipbook_id = "") => {
  try {
    const postData = {
      flipbook_id: flipbook_id,
      price: "10",
      pages: [1, 2, 5, 10, 11, 13],
    };
    const formData = new FormData();
    formData.append("flipbook_id", flipbook_id);
    formData.append("price", "10");
    formData.append("pages[]", JSON.stringify([1, 2, 5, 10, 11, 13]));
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/v1/flipbook/sell-publication`,
      {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      }
    );
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    return await response.json();
  } catch (error) {
    throw new Error("Something went wrong");
  }
};
