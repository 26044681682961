import React, { useState } from 'react';

const Articles = () => {
    const [activeTab, setActiveTab] = useState('Published');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <div className="articles-container">
            <h1 className="articles-title">Articles</h1>
            <div className="controls">
                <div className="tabs">
                    <button
                        className={`tab-button ${activeTab === 'Drafts' ? 'active' : ''}`}
                        onClick={() => handleTabClick('Drafts')}
                    >
                        Drafts
                    </button>
                    <button
                        className={`tab-button ${activeTab === 'Published' ? 'active' : ''}`}
                        onClick={() => handleTabClick('Published')}
                    >
                        Published
                    </button>
                </div>
                <div className="search-bar">
                    <input type="text" placeholder="Search articles list..." />
                </div>
            </div>
            {activeTab === 'Published' ? (
                <div className="no-articles-message border-1">
               
                </div>
            ) : (
                <div className="drafts-content">
                    {/* Render Drafts Content Here */}
                </div>
            )}
        </div>
    );
};

export default Articles;
