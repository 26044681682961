import { getCookie } from "../Components/Utils";

export const fetchCloudSearchApi = async (route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "GET",
    cache: "no-store",
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

export const fetchAPI = async (formData, route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}${route}`, {
    method: "POST",
    cache: "no-store",
    body: formData,
    headers: {
      authToken: getCookie("authToken"),
    },
  });
  return await res.json();
};

export async function getAllNotificationHandler({
  limit = 10,
  offset = 0,
  multiple_module = "1,16",
  recent = 10,
  view_all = 1,
}) {
  const response = await fetch(
    `${process.env.REACT_APP_API_URL}api/v1/public/get-notifications`,
    {
      method: "POST",
      headers: {
        authToken: getCookie("authToken"),
      },
      body: JSON.stringify({ limit, offset, multiple_module, recent }),
    }
  );

  if (!response.ok) {
    const errorData = await response.json();

    const error = new Error(
      "An Error occurred while getting all notifications."
    );
    error.info = errorData;
    error.code = `${response.status}`;
    throw error;
  }

  return await response.json();
}

export async function setNotificationCountHandler({
  notification_id = "0",
  status = "1",
  module = "6",
}) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_URL}api/v1/public/set-notification-status`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json", // Added Content-Type header
          authToken: getCookie("authToken"),
        },
        body: JSON.stringify({ module, status, notification_id }),
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      const error = new Error(
        "An error occurred while setting notification count."
      );
      error.info = errorData;
      error.code = `${response.status}`;
      throw error;
    }

    return await response.json();
  } catch (error) {
    console.error("API Error:", error); // Log the error for debugging
    throw error;
  }
}
