import React, { useState, useRef, useEffect } from "react";
import { pdfjs } from "react-pdf";
import pdf from "../Images/Silo_Dropzone.png";
import pdf1 from "../Images/Axiom_Dropzone.png";
import { useNavigate, Link } from "react-router-dom";
import { FiUpload } from "react-icons/fi";
import { openDB, addItem } from "./IndexedDB";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { VscBook } from "react-icons/vsc";
import { BsBook, BsThreeDots } from "react-icons/bs";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import { BsCurrencyDollar } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { getCookie } from "./Utils";
import { ShimmerCategoryItem } from "react-shimmer-effects";
import { useDispatch } from "react-redux";
import { addFilePath } from "./redux/slice/pdfUrlSlice";
import { GrArticle, GrDocumentImage } from "react-icons/gr";
import { CgProfile } from "react-icons/cg";
import { SlEye } from "react-icons/sl";
import { CiEdit } from "react-icons/ci";
import { AiOutlineShareAlt } from "react-icons/ai";

pdfjs.GlobalWorkerOptions.workerSrc = `/pdf.worker.min.mjs`;

function Dropdown() {
  const [showUpload, setShowUpload] = useState(true);
  const [file, setFile] = useState(null);
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const dropAreaRef = useRef(null);
  const [showPopover, setShowPopover] = useState(null);
  const dispatch = useDispatch();
  const [del, setDel] = useState(null);
  const [data, setData] = useState(null);

  useEffect(() => {
    fetchRecentData();
  }, []);

  const fetchRecentData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-publication`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.list);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deletePublication = async (id) => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/delete-publication/${id}`,
        {
          method: "DELETE",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setDel(responseData?.data?.get_pdf);
      toast.success("Flipbook deleted successfully");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFileUpload = async (file) => {
    const MAX_SIZE = 200 * 1024 * 1024;
    if (file) {
      if (file.size > MAX_SIZE) {
        return toast.error(
          "File size exceeds the 200 MB limit. Please upload a smaller file."
        );
      }

      const db = await openDB();

      // Convert file to base64 with data URI
      const reader = new FileReader();
      reader.onloadend = async () => {
        try {
          const dataUri = reader.result; // Get the data URI
          const fileId = await addItem(db, { file });
          dispatch(addFilePath({ dataUri, fileId }));

          navigate("/sellpublishe");
        } catch (e) {
          toast.error("Error storing the file. Please try again.");
        }
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }

    setFile(file);
    setShowUpload(false);
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    handleFileUpload(file);
  };

  const triggerFileUpload = () => {
    fileInputRef.current.click();
  };

  const handleDragOver = (event) => {
    event.preventDefault();

    event.stopPropagation();
    dropAreaRef.current.classList.add("drag-over");
  };

  const handleDragLeave = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropAreaRef.current.classList.remove("drag-over");
  };

  const handleDrop = (event) => {
    event.preventDefault();
    event.stopPropagation();
    dropAreaRef.current.classList.remove("drag-over");
    const file = event.dataTransfer.files[0];
    handleFileUpload(file);
  };

  useEffect(() => {
    const dropArea = dropAreaRef.current;
    dropArea.addEventListener("dragover", handleDragOver);
    dropArea.addEventListener("dragleave", handleDragLeave);
    dropArea.addEventListener("drop", handleDrop);
    return () => {
      dropArea.removeEventListener("dragover", handleDragOver);
      dropArea.removeEventListener("dragleave", handleDragLeave);
      dropArea.removeEventListener("drop", handleDrop);
    };
  }, []);
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {console.log("showPopover", showPopover)}
        <div className="d-flex flex-column">
          <Link to="/sell">
            <div className="text-left p-2">
              <BsCurrencyDollar className="fs-5 text-dark" />{" "}
              <span className="toggle px-2"> Sell publication</span>
            </div>
          </Link>
          <div className="text-left p-2">
            <GrArticle className="fs-5" />{" "}
            <span className="toggle px-2">Create Articles</span>
          </div>
          <div className="text-left p-2">
            <GrDocumentImage className="fs-5" />{" "}
            <span className="toggle px-2">Create Social Post</span>
          </div>
          <div
            className="text-left p-2"
            onClick={() => deletePublication(showPopover)}
          >
            <AiOutlineDelete className="fs-5" />{" "}
            <span className="toggle px-2">Delete publication </span>
          </div>
        </div>
      </Popover.Body>
    </Popover>
  );

  const [activeButton, setActiveButton] = useState("Publications");

  const handleClick = (button) => {
    setActiveButton(button);
  };

  const navigateToPublish = (id) => {
    navigate(`/sellpublishe/${id}`);
  };

  const handleShare = (item) => {
    if (navigator.share) {
      navigator
        .share({
          title: item.pdf_file_name,
          text: "Check out this flipbook!",
          url: `${process.env.REACT_APP_PUBLIC_URL}sellpublishe/${item}`,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Share not supported on this browser");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container landingpagehome my-3">
        <div
          ref={dropAreaRef}
          className="dropzone container p-3 my-4 drop-area w-100"
          onClick={triggerFileUpload}
        >
          <div className="d-flex justify-content-center">
            {process.env.REACT_APP_API_URL !==
            "https://api.axiomwebcontrol.com/" ? (
              <img
                src={pdf}
                alt="Upload PDF"
                style={{ cursor: "pointer" }}
                className="py-2 img-fluid dropdownimg"
              />
            ) : (
              <img
                src={pdf1}
                alt="Upload PDF"
                style={{ cursor: "pointer" }}
                className="py-2 img-fluid dropdownimg"
              />
            )}

            <input
              id="file-upload"
              type="file"
              accept="application/pdf"
              onChange={handleFileInputChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
          </div>
          <div className="dragdroptext text-center fs-1">Drag & Drop</div>
          <div className="ortext text-center py-1">OR</div>
          <div className="d-flex justify-content-center py-1">
            {process.env.REACT_APP_API_URL !==
            "https://api.axiomwebcontrol.com/" ? (
              <button
                type="button"
                className="btn btn-danger d-flex align-items-center"
              >
                <FiUpload className="btnlogo" />
                <span className="text-light btntext ms-2">Upload File</span>
              </button>
            ) : (
              <button
                type="button"
                className="btn gradient-button d-flex align-items-center"
              >
                <FiUpload className="btnlogo" />
                <span className="text-light btntext ms-2">Upload File</span>
              </button>
            )}
          </div>
        </div>
        <p className="reacentwork">Recent Work</p>
        <div className="button-group">
          <button
            className={`button ${
              activeButton === "Publications" ? "active" : ""
            }`}
            onClick={() => handleClick("Publications")}
          >
            <BsBook className="sidebar-icon1" />{" "}
            <span className="tabtext">Publications</span>
          </button>
          <button
            className={`button ${activeButton === "Articles" ? "active" : ""}`}
            onClick={() => handleClick("Articles")}
          >
            <GrArticle className="sidebar-icon1" />{" "}
            <span className="tabtext">Articles</span>
          </button>
          <button
            className={`button ${
              activeButton === "SocialPosts" ? "active" : ""
            }`}
            onClick={() => handleClick("SocialPosts")}
          >
            <GrDocumentImage className="sidebar-icon1" />
            <span className="tabtext">Social posts</span>
          </button>
          <Link to="/publicprofile">
            <button
              className={`button ${
                activeButton === "Publicprofile" ? "active" : ""
              }`}
            >
              <CgProfile className="sidebar-icon1" />
              <span className="tabtext">Public Profile</span>
            </button>
          </Link>
        </div>
        {data ? (
          data.map((item, idx) => (
            <div class="container mt-3">
              <div class="row card-layout">
                <div className="col-1">
                  <div class="qr-code">
                    <img src={item?.thumbnail} alt="Icon" height={60} />
                  </div>
                </div>
                <div class="col-2 text-section">
                  <div>
                    <VscBook className="font" />
                    <span className="px-2 publishtitle">{item?.title}</span>
                  </div>
                  <div>
                    <span className="createdtext">{item.created_at}</span>
                  </div>
                </div>
                {process.env.REACT_APP_API_URL !==
                "https://api.axiomwebcontrol.com/" ? (
                  <div className="col-4 d-flex justify-content-center">
                    <span class="fwbold">POWERED BY SILOCLOUD</span>
                  </div>
                ) : (
                  <div className="col-4 d-flex justify-content-center">
                    <span class="fwbold">POWERED BY AXIOMWEBCONTROL</span>
                  </div>
                )}
                <div class="col-md-4 justify-content-center button-group">
                  {process.env.REACT_APP_API_URL !==
                  "https://api.axiomwebcontrol.com/" ? (
                    <a
                      download
                      class="btn btn-outline-danger"
                      href={item.download_path}
                    >
                      Download
                    </a>
                  ) : (
                    <a
                      download
                      class="btn btn-outline-primary"
                      href={item.download_path}
                    >
                      Download
                    </a>
                  )}
                  <div>
                    <OverlayTrigger
                      trigger="click"
                      placement="bottom"
                      show={showPopover === item.id}
                      onToggle={() =>
                        setShowPopover(showPopover === item.id ? null : item.id)
                      }
                      overlay={popover}
                    >
                      {process.env.REACT_APP_API_URL !==
                      "https://api.axiomwebcontrol.com/" ? (
                        <button type="button" className="btn btn-danger mx-3">
                          <BsThreeDots />
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn gradient-button mx-3"
                        >
                          <BsThreeDots />
                        </button>
                      )}
                    </OverlayTrigger>
                  </div>
                  <div
                    className="tooltip-container"
                    onClick={() => navigateToPublish(item.flipbook_id)}
                  >
                    <SlEye className="font  tooltip-trigger" />
                    <span className="tooltip-text">View</span>
                  </div>
                  <div
                    className="tooltip-container"
                    onClick={() => navigateToPublish(item.id)}
                  >
                    <CiEdit className="font  tooltip-trigger" />
                    <span className="tooltip-text">Edit</span>
                  </div>
                  <div
                    className="tooltip-container"
                    onClick={() => handleShare(item.id)}
                  >
                    <AiOutlineShareAlt className="font tooltip-trigger" />
                    <span className="tooltip-text">Share</span>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="mt-2">
            <ShimmerCategoryItem
              hasImage
              imageType="thumbnail"
              imageWidth={100}
              imageHeight={100}
              text
              cta
            />
          </div>
        )}
      </div>
    </>
  );
}
export default Dropdown;
