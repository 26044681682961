import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import { Link, useNavigate } from "react-router-dom";
import Thumb from "../Images/PDFthumb-removebg-preview.png";
import NoResultsImage from "../Images/NoResultFound.png";
import { LuFolderClosed } from "react-icons/lu";
import { getCookie } from "./Utils";
import { FaPlus } from "react-icons/fa6";
import { ShimmerTable } from "react-shimmer-effects";
import { toast, ToastContainer } from "react-toastify";
import Swal from "sweetalert2";

export default function CMS() {
  const [data, setData] = useState([]);
  const [folderData, setFolderData] = useState([]);
  const [searchKeywords, setSearchKeyword] = useState("");
  const [activeIndex, setActiveIndex] = useState(null);
  const [noResults, setNoResults] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isloading, setIsLoading] = useState(true);

  useEffect(() => {
    if (folderData) {
      setLoading(false);
    }
  }, [folderData]);

  const [isDeleteVisible, setDeleteVisible] = useState(false);

  const toggleDeleteOption = () => {
    setDeleteVisible(!isDeleteVisible);
  };

  const handleFolderClick = (id) => {
    setActiveIndex(id);
  };

  const allCollections = () => {
    setActiveIndex(null);
  };

  const handleDelete = async (collection_id, pdfId) => {
    try {
      const result = await Swal.fire({
        title: "Are you sure?",
        text: "Do you want to delete this collection?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        // Start loading
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/v1/flipbook/delete/${pdfId}`,
          {
            method: "DELETE",
            headers: {
              authToken: getCookie("authToken"),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const responseData = await response.json();
        if (responseData.status) {
          fetchData(); // Refresh the collection list
          toast.success("Your collection has been deleted");
        } else {
          toast.error(responseData.message);
        }
        console.log(responseData);
        // Stop loading once data is fetched
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("An error occurred while deleting the collection.");
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchKeywords, activeIndex]);

  const fetchData = async () => {
    try {
      setLoading(true); // Start loading
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-flipbooks?search_keyword=${searchKeywords}&collection_id=${activeIndex}`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      const pdfs = responseData?.data?.pdf_list || [];
      setData(pdfs);
      setNoResults(pdfs.length === 0);
      setLoading(false);
      // Stop loading once data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false); // Stop loading even if there's an error
    }
  };

  const searchFlipBook = (event) => {
    setSearchKeyword(event.target.value);
  };

  const fetchDatafolder = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/collection`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setFolderData(responseData?.data?.collections);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDatafolder();
  }, []);

  const navigate = useNavigate();

  const navigateToFlipbook = (id) => {
    navigate(`/clickToFlipbook/${id}`);
  };

  const navigateToPublish = (id) => {
    navigate(`/sellpublishe/${id}`);
  };

  const handleShare = (item) => {
    if (navigator.share) {
      navigator
        .share({
          title: item.pdf_file_name,
          text: "Check out this flipbook!",
          url: `${process.env.REACT_APP_PUBLIC_URL}clickToFlipbook/${item}`,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      console.log("Share not supported on this browser");
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="container-fluid collection-container">
        <div className="row">
          <div
            className={`col-12 col-md-3 col-lg-2 border-end p-0 d-none d-md-block`}
          >
            <div className="sidebar p-3">
              <div
                className={`sidebar-item ${
                  activeIndex === null ? "active" : ""
                }`}
                onClick={allCollections}
              >
                <LuFolderClosed
                  className={`sidebaricon ${
                    activeIndex === null ? "active-icon" : ""
                  }`}
                />
                <span
                  className={`sidebartext ${
                    activeIndex === null ? "active-text" : ""
                  }`}
                >
                  All Collections
                </span>
              </div>

              {isloading ? (
                <ShimmerTable row={6} col={1} />
              ) : (
                folderData &&
                folderData.map((item, idx) => (
                  <div
                    key={idx}
                    onClick={() => handleFolderClick(item.id)}
                    className={`sidebar-item ${
                      activeIndex === item.id ? "active" : ""
                    }`}
                  >
                    <LuFolderClosed
                      className={`sidebaricon ${
                        activeIndex === item.id ? "active-icon" : ""
                      }`}
                    />
                    <span
                      className={`sidebartext ${
                        activeIndex === item.id ? "active-text" : ""
                      }`}
                    >
                      {item.collection_name}
                    </span>
                  </div>
                ))
              )}
            </div>
          </div>
          <div className="col-lg-10 col-md-12">
            <div className="d-flex justify-content-between py-3 px-2">
              <p className="collectionheader">MY FLIPBOOKS</p>
              {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
                <>
                  <div className="d-flex justify-content-end">
                    <Link to="/dropdown">
                      <div>
                        <button
                          type="button"
                          className="btn btn-danger btnsize d-flex align-items-center "
                        >
                          <FaPlus className="btnlogo" />
                          <span className="btntext">Create</span>
                        </button>
                      </div>
                    </Link>
                  </div>
                </>
              ) : (
                <div className="d-flex justify-content-end">
                  <Link to="/dropdown">
                    <button
                      type="button"
                      className="btn gradient-button btnsizeaxiom d-flex align-items-center"
                    >
                      <FaPlus className="btnlogo" />
                      <span className="btntext">Create</span>
                    </button>
                  </Link>
                </div>
              )}
            </div>
            <div className="p-0">
              <div className="rounded search-container px-4 py-1">
                <div className="w-25 form-group">
                  <Label className="searchheader">MY FLIPBOOK</Label>
                  <div className="form-control-wrap">
                    <input
                      className="form-control customfont searchtext"
                      type="text"
                      id="default-2"
                      autoComplete="off"
                      placeholder="Search here"
                      onChange={searchFlipBook}
                      autoFocus
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="">
              <div className="my-3 thumbnailbox rounded">
                <div className="row py-1 px-0 mx-0">
                  {loading ? (
                    <div className="row">
                      {Array(6)
                        .fill()
                        .map((_, idx) => (
                          <>
                            <div
                              key={idx}
                              className="col-lg-2 col-md-3 col-sm-6 py-4"
                            >
                              <div className="shimmerwrapper2">
                                <div className="shimmerimage2"></div>
                                <div className="shimmertext2"></div>
                              </div>
                            </div>
                            <div key={idx} className="col-lg-2 col-md-3 py-4">
                              <div className="shimmerwrapper2">
                                <div className="shimmerimage2"></div>
                                <div className="shimmertext2"></div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  ) : noResults ? (
                    <div className="col-12 text-center p-5">
                      <img
                        src={NoResultsImage}
                        height={200}
                        alt="No results found"
                        className="no-results-image"
                      />
                      <p>No results found</p>
                    </div>
                  ) : (
                    data.map((item, idx) => (
                      <div
                        key={idx}
                        className="col-lg-2 col-md-3 pdf-container py-4"
                      >
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => navigateToFlipbook(item.id)}
                        >
                          <img
                            src={item.thumbnail}
                            alt="Thumbnail"
                            className="ThumbHeight"
                          />
                          <div className="pdfname text-center">
                            {item.pdf_file_name}
                          </div>
                          <div className="pdfsize text-center">
                            {item.pdf_file_size_kb}
                          </div>
                        </span>

                        {/* Three dots for menu */}
                        <span
                          className="menu-icon "
                          onClick={toggleDeleteOption}
                        >
                          <i className="fas fa-ellipsis-h hover-menu-icon"></i>

                          {/* Delete and Share options */}
                          {isDeleteVisible && (
                            <div className="menu-options">
                              <div className="delete-option">
                                <div
                                  className="d-flex shareoption"
                                  onClick={() => navigateToFlipbook(item.id)}
                                >
                                  <i className="fas fa-eye d-flex align-items-center dsicon px-2"></i>
                                  <span className="dstext px-1">View</span>
                                </div>
                                <div
                                  className="d-flex shareoption"
                                  onClick={() => handleShare(item.id)}
                                >
                                  <i className="fas fa-share-alt d-flex align-items-center dsicon px-2"></i>
                                  <span className="dstext px-2">Share</span>
                                </div>
                                <div
                                  className="d-flex shareoption"
                                  onClick={() =>
                                    handleDelete(item.collection_id, item.id)
                                  }
                                >
                                  <i className="fas fa-trash-alt d-flex align-items-center dsicon px-2"></i>
                                  <span className="dstext px-2">Delete</span>
                                </div>

                                <div
                                  className="d-flex shareoption"
                                  onClick={() => navigateToPublish(item.id)}
                                >
                                  <i className="fas fa-book-open d-flex align-items-center dsicon px-2"></i>
                                  <span className="dstext px-1">Publish</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </span>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
