import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import PageFlip from "react-pageflip";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import { getCookie } from "./Utils";
import { Link, useParams } from "react-router-dom";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import flipSound from "../Sound/page-flip.ogg";
import {
  FaPlay,
  FaPause,
  FaSearchPlus,
  FaSearchMinus,
  FaDownload,
  FaVolumeUp,
  FaVolumeMute,
  FaExpand,
  FaCompress,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaList,
  FaTh,
  FaChevronRight,
  FaChevronLeft,
  FaRegFolder,
} from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import { PDFDocument } from "pdf-lib";
import { getDocument } from "pdfjs-dist";

pdfMake.vfs = pdfFonts.pdfMake.vfs;
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://unpkg.com/pdfjs-dist@latest/es5/build/pdf.worker.min.js";

const PDFFlipBook = () => {
  const [numPages, setNumPages] = useState(null);
  const pageFlipRef = useRef(null);
  const [show, setShow] = useState(false);
  const [pdfUrl, setpdfUrl] = useState(null);
  const [isMuted, setIsMuted] = useState(false);
  const [pages, setPages] = useState([]);
  const [audio] = useState(new Audio(flipSound));
  const [isPlaying, setIsPlaying] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [thumbnails, setThumbnails] = useState([]);
  const [loading, setLoading] = useState(false);
  const { pdf_id } = useParams();
  const flipIntervalRef = useRef(null);
  const bookRef = useRef(null);
  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));

  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));

  const toggleMute = () => setIsMuted(!isMuted);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);

    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const toggleTableOfContents = () =>
    setShowTableOfContents(!showTableOfContents);
  const handleThumbnailClick = (index) => {
    pageFlipRef.current.pageFlip().turnToPage(index);
  };

  const handlePrevPage = () => {
    if (pageFlipRef.current) {
      pageFlipRef.current.pageFlip().flipPrev();
    }
  }

  const handleNextPage = () => {
    if (pageFlipRef.current) {
      pageFlipRef.current.pageFlip().flipNext();
    }
  };

  const handleFirstPage = () => {
    if (pageFlipRef.current) {
      pageFlipRef.current.pageFlip().turnToPage(0);
    }
  };

  const handleLastPage = () => {
    if (pageFlipRef.current) {
      const totalPages = pageFlipRef.current.pageFlip().getPageCount();
      pageFlipRef.current.pageFlip().turnToPage(totalPages - 1);
    }
  };

  useEffect(() => {
    fetchDatafolder();
    fetchApiPdf();
  }, []);

  useEffect(() => {
    const loadPages = async () => {
      const pagesArray = [];
      for (let i = 1; i <= numPages; i++) {
        const canvas = await document.querySelector(
          `.demoPage canvas:nth-child(${i})`
        );
        if (canvas) {
          pagesArray.push(canvas.toDataURL("image/jpeg"));
        }
      }
      setPages(pagesArray);
    };
    if (numPages) loadPages();
  }, [numPages]);
  useEffect(() => {
    if (numPages && pageFlipRef.current) {
      // You can perform actions that require pageFlipRef.current here
    }
  }, [numPages]);

  const fetchDatafolder = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-flipbooks`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchApiPdf = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/public/get-tempurl?pdf_id=${pdf_id}`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      const responseData = await response.json();
      const newdata = await responseData.data.pdf_file_url;

      setpdfUrl(newdata);
    } catch (error) {
      console.error(error);
    }
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setShow(true);
  };

  const handleFlip = () => {
    if (!isMuted) {
      audio.play();
    }
  };
  useEffect(() => {
    if (pdfUrl) {
      console.log("PDF URL: " + pdfUrl);
      fetch(pdfUrl)
        .then((response) => response.arrayBuffer())
        .then(async (pdfData) => {
          const typedArray = new Uint8Array(pdfData);
          const pdf = await getDocument(typedArray).promise;
          const totalPages = pdf.numPages;

          // Initialize an array to store thumbnails
          const thumbnailArray = Array.from({ length: totalPages }, (_, i) => ({
            pageNum: i + 1,
            thumbnail: null,
          }));

          setThumbnails(thumbnailArray); // Set initial state
          await generateThumbnails(pdf, totalPages);
          setLoading(false); // Set loading to false after generating thumbnails
        })
        .catch((error) => {
          console.error("Error loading PDF from URL: ", error);
          setLoading(false); // Set loading to false if an error occurs
        });
    }
  }, [pdfUrl]);
  console.log(thumbnails);

  const generateThumbnails = async (pdf, totalPages) => {
    const newThumbnails = []; // Array to accumulate thumbnails

    for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 0.5 });

      // Create a canvas for each page
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      // Render the page
      await page.render(renderContext).promise;

      // Convert the canvas to base64 image
      const base64Data = canvas.toDataURL("image/png");

      // Add thumbnail data to the array
      newThumbnails.push({ pageNum, thumbnail: base64Data });
    }

    // Update state with all the generated thumbnails at once
    setThumbnails(newThumbnails);
  };

  const togglePlayPause = () => {
    setIsPlaying((prevState) => !prevState);
    if (!isPlaying) {
      // Start auto flipping
      flipIntervalRef.current = setInterval(() => {
        if (
          pageFlipRef.current.pageFlip().getCurrentPageIndex() <
          numPages - 1
        ) {
          pageFlipRef.current.pageFlip().flipNext();
        } else {
          clearInterval(flipIntervalRef.current); // Stop at the last page
          setIsPlaying(false);
        }
      }, 1000); // Flip every 2 seconds
    } else {
      // Stop auto flipping
      clearInterval(flipIntervalRef.current);
    }
  };

  const downloadClick = async () => {
    toast.info("Preparing download...");

    try {
      const pdfDoc = await PDFDocument.create();
      const existingPdfBytes = await fetch(pdfUrl).then((res) =>
        res.arrayBuffer()
      );
      const existingPdfDoc = await PDFDocument.load(existingPdfBytes);

      for (let i = 0; i < numPages; i++) {
        const [page] = await pdfDoc.copyPages(existingPdfDoc, [i]);
        pdfDoc.addPage(page);
      }

      const pdfBytes = await pdfDoc.save();

      // Create a blob and trigger download
      const blob = new Blob([pdfBytes], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "flipbook.pdf";
      link.click();

      toast.success("Download complete!");
    } catch (error) {
      console.error("Error downloading PDF:", error);
      toast.error("Failed to download PDF.");
    }
  };

  const loadPdfThumbnails = async (fileData) => {
    try {
      setLoading(true);
      setThumbnails([]);

      const loadingTask = getDocument({ data: fileData });
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;
      const thumbnailList = [];

      for (let pageNumber = 1; pageNumber <= numPages; pageNumber++) {
        const page = await pdf.getPage(pageNumber);
        const viewport = page.getViewport({ scale: 1.5 });

        // Create a new canvas for each page
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.width = viewport.width;
        canvas.height = viewport.height;

        // Render the page to the new canvas
        await page.render({ canvasContext: context, viewport }).promise;

        // Get the image data URL from the canvas
        const thumbnail = canvas.toDataURL("image/png", 1.0);
        thumbnailList.push(thumbnail);
      }

      // Set the generated thumbnails in the state
      setThumbnails(thumbnailList);
    } catch (error) {
      console.error("Error loading PDF:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
    <ToastContainer/>
      <div className="view-container" style={{ overflow: "hidden" }}>
        {showTableOfContents && (
          <div className="thumbnails-sidebar">
            {thumbnails.map((thumbnailObj, index) => (
              <div key={index} className="thumbnail-container">
                <img
                  src={thumbnailObj.thumbnail} // Access the 'thumbnail' property here
                  alt={`Page ${thumbnailObj.pageNum}`}
                  className="thumbnail"
                  onClick={() => handleThumbnailClick(index)}
                />
                <div className="page-number text-center">
                  {thumbnailObj.pageNum}
                </div>
              </div>
            ))}
          </div>
        )}

        <div className="p-2 d-flex justify-content-end">
          <Link to="/collection">
            {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
              <button
                type="button"
                className="btn btn-danger btnsize d-flex align-items-center"
              >
                <FaRegFolder className="btnlogo" />
                <span className="btntext">Collection</span>
              </button>
            ) : (
              <button
                type="button"
                className="btn gradient-button btnsizeaxiom btnsize d-flex align-items-center"
              >
                <FaRegFolder className="btnlogo" />
                <span className="btntext">Collection</span>
              </button>
            )}
          </Link>
        </div>
        <div className="clicktoflipbook">
          <div
            style={{ transform: `scale(${zoomLevel})`, position: "relative" }}
          >
            <Document
              file={pdfUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              className="flipbook-wrapper1"
            >
              {numPages && (
                <PageFlip
                  ref={pageFlipRef}
                  width={400}
                  height={500}
                  flipOnTouch
                  onFlip={handleFlip}
                  showCover={true}
                >
                  {[...Array(numPages).keys()].map((pageIndex) => (
                    <div key={pageIndex} className="demoPage">
                      <Page pageNumber={pageIndex + 1} width={400} />
                    </div>
                  ))}
                </PageFlip>
              )}
            </Document>
          </div>
        </div>
        <div className="toolbox d-flex justify-content-center">
          <div className="tool text-center" onClick={handleFirstPage}>
            <FaAngleDoubleLeft />
          </div>
          <div
            className="tool text-center"
            onClick={handlePrevPage}
            disabled={currentPage === 1}
          >
            <FaChevronLeft />
          </div>
          <div
            className="tool text-center"
            onClick={handleNextPage}
            disabled={currentPage === numPages}
          >
            <FaChevronRight />
          </div>
          <div className="tool text-center" onClick={handleLastPage}>
            <FaAngleDoubleRight />
          </div>
          <div className="tool text-center" onClick={toggleTableOfContents}>
            {showTableOfContents ? <FaTh /> : <FaList />}
          </div>
          <div className="tool text-center" onClick={zoomIn}>
            <FaSearchPlus />
          </div>
          <div className="tool text-center" onClick={zoomOut}>
            <FaSearchMinus />
          </div>
          <div className="tool text-center" onClick={togglePlayPause}>
            {isPlaying ? <FaPause /> : <FaPlay />}
          </div>
          <div className="tool text-center" onClick={toggleMute}>
            {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
          </div>
          <div className="tool text-center" onClick={toggleFullScreen}>
            {isFullScreen ? <FaCompress /> : <FaExpand />}
          </div>
          <div className="tool text-center" onClick={downloadClick}>
            <FaDownload />
          </div>
        </div>
      </div>
    </>
  );
};

export default PDFFlipBook;
