import React, { useState, useEffect } from "react";
import { getDocument, GlobalWorkerOptions } from "pdfjs-dist";
import { ShimmerThumbnail } from "react-shimmer-effects";

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.11.174/pdf.worker.min.js`;

const ThumbnailURL = ({ pdfUrl, onSelectedPagesChange }) => {
  const [thumbnails, setThumbnails] = useState([]);
  const [selectedPages, setSelectedPages] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loading, setLoading] = useState(true); // Add loading state
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    setIsClicked(true);
  };

  useEffect(() => {
    if (pdfUrl) {
      fetch(pdfUrl)
        .then((response) => response.arrayBuffer())
        .then(async (pdfData) => {
          const typedArray = new Uint8Array(pdfData);
          const pdf = await getDocument(typedArray).promise;
          const totalPages = pdf.numPages;
          setTotalPages(totalPages);

          const thumbnailArray = Array.from({ length: totalPages }, (_, i) => ({
            pageNum: i + 1,
            thumbnail: null,
          }));

          setThumbnails(thumbnailArray);
          await generateThumbnails(pdf, totalPages);
          setLoading(false); // Set loading to false after generating thumbnails
        })
        .catch((error) => {
          console.error("Error loading PDF from URL: ", error);
          setLoading(false); // Set loading to false if an error occurs
        });
    }
  }, [pdfUrl]);

  const generateThumbnails = async (pdf, totalPages) => {
    for (let pageNum = 1; pageNum <= totalPages; pageNum++) {
      const page = await pdf.getPage(pageNum);
      const viewport = page.getViewport({ scale: 0.5 });

      // Create a canvas for each page
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      const renderContext = {
        canvasContext: context,
        viewport: viewport,
      };

      // Render the page
      await page.render(renderContext).promise;

      // Convert the canvas to base64 image
      const base64Data = canvas.toDataURL("image/png");

      // Set the thumbnail for the current page
      setThumbnails((prevThumbnails) =>
        prevThumbnails.map((thumb) =>
          thumb.pageNum === pageNum
            ? { ...thumb, thumbnail: base64Data }
            : thumb
        )
      );
    }
  };

  const handleCheckboxChange = (pageNum) => {
    const updatedSelectedPages = selectedPages.includes(pageNum)
      ? selectedPages.filter((p) => p !== pageNum)
      : [...selectedPages, pageNum];

    setSelectedPages(updatedSelectedPages);
    onSelectedPagesChange(updatedSelectedPages);
  };

  return (
    <div>
      {/* Show loading message while thumbnails are being generated */}
      {loading ? (
        <ShimmerThumbnail height={200} rounded width={150} />
      ) : (
        <div style={{ display: "flex", flexWrap: "wrap" }}>
          {thumbnails.map(({ pageNum, thumbnail }) => (
            <div
              key={pageNum}
              style={{
                position: "relative",
                margin: "10px",
                border: "1px solid #ccc",
              }}
            >
              <input
                type="checkbox"
                className="form-check-input"
                style={{
                  position: "absolute",
                  top: "9px",
                  left: "30px",
                }}
                checked={selectedPages.includes(pageNum)}
                onChange={() => handleCheckboxChange(pageNum)}
              />
              {thumbnail ? (
                <img
                  src={thumbnail}
                  alt={`PDF Thumbnail of page ${pageNum}`}
                  style={{
                    width: "210px",
                    height: "270px",
                    cursor: "pointer",
                  }}
                  onClick={() => handleCheckboxChange(pageNum)}
                />
              ) : (
                <div
                  style={{
                    width: "210px",
                    height: "270px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    backgroundColor: "#f0f0f0",
                  }}
                >
                  Loading...
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ThumbnailURL;
