import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import HTMLFlipBook from "react-pageflip";
import * as pdfjsLib from "pdfjs-dist";
import { Link } from "react-router-dom";
import { Tooltip } from "reactstrap";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import {
  FaPlay,
  FaPause,
  FaSearchPlus,
  FaSearchMinus,
  FaShareAlt,
  FaDownload,
  FaVolumeUp,
  FaVolumeMute,
  FaExpand,
  FaCompress,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaList,
  FaTh,
  FaChevronRight,
  FaChevronLeft,
} from "react-icons/fa";
import { getCookie } from "./Utils";
import { FiSave } from "react-icons/fi";
import { RiDeleteBin6Line } from "react-icons/ri";
import styled, { keyframes } from "styled-components";
import { GlobalWorkerOptions } from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.min.mjs";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import ClickToFlipbook from "../Components/ClickToFlipbook";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

GlobalWorkerOptions.workerSrc = `https://api.silocloud.io/pdfworker/pdf.worker.min.js`;

function FlipbookFetch({ sendFileData }) {
  const navigate = useNavigate();
  const [fileData, setFileData] = useState(null);
  const [pages, setPages] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [showTableOfContents, setShowTableOfContents] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [playInterval, setPlayInterval] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tooltipOpenDiscard, setTooltipOpenDiscard] = useState(false);
  const [tooltipOpenSave, setTooltipOpenSave] = useState(false);
  const [dropdownValue, setDropdownValue] = useState("");
  const [data, setData] = useState([]);
  const bookRef = useRef(null);
  const audioRef = useRef(null);

  //show new collection in dropdown list -Show Collection API
  const { id } = useParams();
  const fetchData = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/collection`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setData(responseData?.data?.collections);
      console.log(responseData);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleFlip = () => {
    if (audioRef.current && !isMuted) {
      audioRef.current.play();
    }
  };

  useEffect(() => {
    const handleFlip = () => {
      if (audioRef.current && !isMuted) {
        audioRef.current.play();
      }
    };

    if (bookRef.current) {
      bookRef.current.pageFlip().on("flip", handleFlip);
    }

    return () => {
      if (bookRef.current) {
        bookRef.current.pageFlip().off("flip", handleFlip);
      }
    };
  }, [isMuted]);

  function base64ToBlob(base64, contentType = "", sliceSize = 512) {
    localStorage.setItem("uploadedFile", base64);
    const byteCharacters = atob(base64);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }
  const fetchAndConvertToBase64 = async (url) => {
    try {
      // Fetch the file from the URL
      const response = await fetch(url);
      // Check if the fetch was successful
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      // Read the response as a blob
      const blob = await response.blob();
      // Create a FileReader to convert the blob to a Base64 encoded string
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        const base64data = reader.result;
        // Prepend the required prefix
        const prefixedBase64data = `data:application/pdf;base64,${
          base64data.split(",")[1]
        }`;
        console.log(prefixedBase64data); // This is the Base64 encoded string with the prefix
      };
    } catch (error) {
      console.error("Error fetching or converting file:", error);
    }
  };

  useEffect(() => {
    const fetchApiPdf = async () => {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/get-pdf?pdf_id=72`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      const responseData = await response.json();
      const newdata = responseData.data.get_pdf.pdf_file;
      setFileData(newdata);
    };
    // const data = localStorage.getItem("uploadedFile");
    // if (!data) {
    //   navigate("/");
    //   return;
    // }
    // setFileData(data);
    fetchApiPdf();
    if (fileData) {
      if (data.startsWith("data:application/pdf")) {
        const pdfData = atob(data.split(",")[1]);
        const loadingTask = pdfjsLib.getDocument({ data: pdfData });
        loadingTask.promise.then((pdf) => {
          const numPages = pdf.numPages;
          const loadedPages = [];
          const renderPage = (pageNum) => {
            pdf.getPage(pageNum).then((page) => {
              const viewport = page.getViewport({ scale: 1.5 });
              const canvas = document.createElement("canvas");
              const context = canvas.getContext("2d");
              canvas.height = viewport.height;
              canvas.width = viewport.width;
              const renderContext = {
                canvasContext: context,
                viewport: viewport,
              };
              page.render(renderContext).promise.then(() => {
                loadedPages.push(canvas.toDataURL());
                if (loadedPages.length === numPages) {
                  setPages(loadedPages);
                } else {
                  renderPage(pageNum + 1);
                }
              });
            });
          };
          renderPage(1);
        });
      }
    }

    return () => {
      if (bookRef.current) {
        bookRef.current.pageFlip().off("flip", handleFlip);
      }
    };
  }, [navigate]);

  // useEffect(() => {
  //   sendFileData(fileData);
  // }, [fileData]);

  useEffect(() => {
    if (isPlaying) {
      const intervalId = setInterval(() => {
        bookRef.current.pageFlip().flipNext();
      }, 1500); // Change the interval time as needed
      setPlayInterval(intervalId);
    } else {
      clearInterval(playInterval);
    }

    return () => clearInterval(playInterval);
  }, [isPlaying]);

  if (!fileData) {
    return (
      <div>
        <ShimmerSimpleGallery imageType="circular" imageHeight={200} caption />
      </div>
    );
  }

  const toggleTableOfContents = () =>
    setShowTableOfContents(!showTableOfContents);

  const handleThumbnailClick = (index) =>
    bookRef.current.pageFlip().turnToPage(index);

  const handlePrevPage = () => bookRef.current.pageFlip().flipPrev();
  const handleNextPage = () => bookRef.current.pageFlip().flipNext();
  const handleFirstPage = () => bookRef.current.pageFlip().turnToPage(0);

  const handleLastPage = () =>
    bookRef.current.pageFlip().turnToPage(pages.length - 1);
  const zoomIn = () =>
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2));
  const zoomOut = () =>
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 0.5));

  const toggleMute = () => setIsMuted(!isMuted);
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);

    if (!isFullScreen) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  //  click on download icon open swal  for password create

  // const downloadclick = () => {
  //   Swal.fire({
  //     title: "Create your password for protected PDF",
  //     input: "text",
  //     inputAttributes: {
  //       autocapitalize: "off"
  //     },
  //     showCancelButton: true,
  //     confirmButtonText: "Download",
  //     showLoaderOnConfirm: true,
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       pdfDownload(result.value);
  //     }
  //   });
  // };

  // download the pdf with watermark
  const downloadclick = (password) => {
    const watermarkText = "Silo Publisher";

    // Create the watermark object
    const watermark = {
      text: watermarkText,
      fontSize: 55,
      color: "red",
      opacity: 0.5,
      angle: 45,
      margin: [0, 0, 0, 0],
    };

    const docDefinition = {
      content: pages.map((page) => ({
        stack: [
          {
            image: page,
            width: 595.28,
            height: 841.89,
            absolutePosition: { x: 0, y: 0 },
          },
          {
            text: watermarkText,
            fontSize: 55,
            color: "red",
            opacity: 0.1,
            angle: 45,
            absolutePosition: { x: 130, y: 400 },
            margin: [0, 0, 0, 0],
          },
        ],
        pageBreak: "after",
      })),
      // pageSize: 'A4',
      // pageMargins: [40, 60, 40, 60],
      // userPassword: password,
      // ownerPassword: password,
      // permissions: {
      //   printing: 'highResolution',
      //   modifying: false,
      //   copying: false,
      //   annotating: false,
      //   fillingForms: false,
      //   contentAccessibility: false,
      //   documentAssembly: false,
      // },
    };

    pdfMake.createPdf(docDefinition).download("");
  };

  // share the pdf
  const handleShare = () => {
    if (navigator.share) {
      navigator.share({
        title: "Check out my flipbook!",
        url: window.location.href,
      });
    } else {
      alert("Share API not supported in this browser.");
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  console.log(data);

  //click on save button then open this swal with dropdown for select collection name
  const saveClick = () => {
    console.log("No auth token");

    let at = Cookies.get("authToken");
    if (!at) {
      console.log("No auth token");
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    }
    //  else {
    // fetchData();
    Swal.fire({
      title: "Save as",
      html: `<div style="display: flex; align-items: center;">
                <select id="swal-select" class="form-select form-select-lg swal2-input" aria-label=".form-select-lg example" >
                  ${
                    data
                      ? data
                          .map((item, index) => {
                            return `<option>${item?.collection_name}</option>`;
                          })
                          .join("")
                      : "No data available"
                  }
                </select>
                <button id="add-collection-btn" class="swal2-confirm swal2-styled" style="background-color: #3085d6; color: white; border: none; padding: 8px 12px; cursor: pointer; margin-left: 10px;">
                  <i class="fas fa-plus"></i>
                </button>
            </div>`,
      inputPlaceholder: "Enter your Collection name",
      inputAttributes: {
        maxlength: 30,
      },
      showCancelButton: true,
      confirmButtonText: "Save",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const selectedValue = document.getElementById("swal-select").value;
        const collectionName = `${selectedValue} - ${result.value}`;
        handleClick(collectionName);
      }
    });

    // Handling the plus icon click to add a new collection
    document
      .getElementById("add-collection-btn")
      .addEventListener("click", handleAddCollection);
    //  }
  };

  //swal of create new collection
  const handleAddCollection = () => {
    Swal.fire({
      title: "Add New Collection",
      input: "text",
      inputPlaceholder: "Enter new collection name",
      showCancelButton: true,
      confirmButtonText: "Add",
      cancelButtonText: "Cancel",
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result.isConfirmed) {
        //  new collection
        console.log("New collection name:", result.value);
        sendCollection(result.value);
        // Optionally update
        setDropdownValue(result.value);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //  Cancel is clicked, open main "Save as" modal
        saveClick();
      }
    });
  };

  //create new collection name and save into dropdown list -Create Collection API
  const sendCollection = async (collectionName) => {
    const formData = new FormData();
    formData.append("collection_name", collectionName);
    try {
      const response = await fetch(
        "https://api.silocloud.io/api/v1/flipbook/collection",
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );
      const storeVal = await response.json();
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      console.log(storeVal);
      fetchData();
    } catch (e) {
      console.log("error", e);
    }
  };

  //save the pdf in collection page -Upload pdf API
  const handleClick = async () => {
    setLoading(true);
    try {
      const binaryBlob = base64ToBlob(
        fileData.split(",")[1],
        "application/pdf"
      );

      const formData = new FormData();
      formData.append("pdf_file", binaryBlob, "uploadedFile.pdf");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/upload-pdf`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      if (result.status) {
        console.log("Data saved successfully:", result);
        Swal.fire({
          position: "center-center",
          icon: "success",
          title: "Your file has been Uploaded.",
          showConfirmButton: false,
          timer: 1000,
        });
        navigate("/collection");
        setLoading(false);
      } else {
        console.log("issue:", result);
        Swal.fire({
          position: "center-center",
          icon: "error",
          title: "Something went wrong!",
          showConfirmButton: false,
          timer: 1000,
        });
        setLoading(false);
      }
    } catch (error) {
      console.error("Error saving data:", error);
      setLoading(false);
    }
  };

  const toggleDiscardTooltip = () => setTooltipOpenDiscard(!tooltipOpenDiscard);
  const toggleSaveTooltip = () => setTooltipOpenSave(!tooltipOpenSave);

  return (
    <>
      <div className="flipbook-container">
        <div>
          <ClickToFlipbook pdfUrl={fileData} />
        </div>
        <div className="toolbox">
          <div className="toolbox-inner">
            <div className="tool" onClick={handleFirstPage}>
              <FaAngleDoubleLeft />
            </div>
            <div
              className="tool"
              onClick={handlePrevPage}
              disabled={currentPage === 1}
            >
              <FaChevronLeft />
            </div>
            <div
              className="tool"
              onClick={handleNextPage}
              disabled={currentPage === numPages}
            >
              <FaChevronRight />
            </div>
            <div className="tool" onClick={handleLastPage}>
              <FaAngleDoubleRight />
            </div>
            <div className="tool" onClick={toggleTableOfContents}>
              {showTableOfContents ? <FaTh /> : <FaList />}
            </div>
            <div className="tool" onClick={zoomIn}>
              <FaSearchPlus />
            </div>
            <div className="tool" onClick={zoomOut}>
              <FaSearchMinus />
            </div>
            <div className="tool" onClick={togglePlayPause}>
              {isPlaying ? <FaPause /> : <FaPlay />}
            </div>
            <div className="tool" onClick={toggleMute}>
              {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
            </div>
            <div className="tool" onClick={toggleFullScreen}>
              {isFullScreen ? <FaCompress /> : <FaExpand />}
            </div>

            <div className="tool" onClick={downloadclick}>
              <FaDownload />
            </div>

            <div className="tool" onClick={handleShare}>
              <FaShareAlt />
            </div>
          </div>
        </div>
        <div className="button-container">
          <div className="d-flex justify-content-between">
            <Link to="/dropdown">
              <div className="discardbutton">
                <button
                  id="discardButton"
                  type="button"
                  className="btn btn-danger d-flex align-items-center"
                >
                  <RiDeleteBin6Line className="btnlogo" />
                  <span className="text-light btntext ms-2">Discard</span>
                </button>
                <Tooltip
                  placement="top"
                  isOpen={tooltipOpenDiscard}
                  target="discardButton"
                  toggle={toggleDiscardTooltip}
                >
                  Discard the current file
                </Tooltip>
              </div>
            </Link>
            <div className="savebutton">
              <button
                id="saveButton"
                type="button"
                className="btn btn-success d-flex align-items-center"
                onClick={saveClick}
              >
                <FiSave className="btnlogo" />
                <span className="text-light btntext ms-2">Save</span>
              </button>
              <Tooltip
                placement="top"
                isOpen={tooltipOpenSave}
                target="saveButton"
                toggle={toggleSaveTooltip}
              >
                Save the current file
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FlipbookFetch;
