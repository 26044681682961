import React, { useState, useEffect, useRef } from "react";
import { getCookie } from "./Utils";
import Cookies from "js-cookie";
import AccountAvatar from "./AccountAvatar";
import BlockchainACC from "../Images/applogos/BlockchainACC.png";
import { LuWallet2 } from "react-icons/lu";
import { IoSettingsOutline } from "react-icons/io5";
import { PiSignOut } from "react-icons/pi";
import { useTheme } from "../Context/ThemeContext";
import BlockchainACC2 from "../Images/applogos/BlockchainACC2.png";
import "../CSS/Header.css";
import { GoMoon } from "react-icons/go";
import { FiSun } from "react-icons/fi";

const User = () => {
  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  const { theme, toggleTheme } = useTheme();

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const body = document.body;
    if (theme === "dark") {
      body.setAttribute("light-mode", "dark");
      body.classList.add("dark-mode");
    } else {
      body.setAttribute("light-mode", "light");
      body.classList.remove("dark-mode");
    }
  }, [theme]);

  const setLogout = (e) => {
    e.preventDefault();
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    const logoutUrl = `${process.env.REACT_APP_ACCOUNT_URL}logout`;
    window.location.href = logoutUrl;
  };

  return (
    <div className={`hero ${theme}`}>
      <div className="dropdown" ref={dropdownRef}>
        <div
          href="#toggle"
          onClick={(ev) => {
            ev.preventDefault();
            setOpen((prevState) => !prevState);
          }}
          style={{ cursor: "pointer" }}
          className="text-wrap"
          id="userDropdown"
          aria-expanded={open}
        >
          <AccountAvatar profileName={getCookie("username", "John")} />
        </div>

        <div
          className={`dropdown-menu menuUser  p-0 rounded-2 shadow  ${
            open ? "show" : ""
          }`}
        >
          <div
            className=" d-block rounded-2 "
            style={{
              backgroundColor: theme === "dark" ? "#1B1B1B" : "#FEFFFE  ",
              color: theme === "dark" ? "#B6C6E3" : "#white",
            }}
          >
            <div className=" d-flex notifibox2  px-4 py-3 rounded-top-2 border-bottom bg-lighter">
              <AccountAvatar profileName={getCookie("username", "John")} />
              <div className="d-grid ms-2 w-100">
                <span
                  className={`fw-bold w-100 ${
                    theme === "dark" ? "lead-text2" : "lead-text"
                  }`}
                >
                  {getCookie("username", "John")}
                </span>
                <span className="sub-text">
                  {getCookie("email", "john@silocloud.io")}
                </span>
              </div>
            </div>

            <div className="px-0 rounded-2 ">
              <div className="py-2 link-list">
                {process.env.REACT_APP_API_URL !==
                "https://api.axiomwebcontrol.com/" ? (
                  <li
                    className={`px-4 ${
                      theme === "dark" ? "userList3" : "userList2"
                    }`}
                  >
                    <a
                      href="https://silocloud.com/enumblockchain-explorer/"
                      onClick={() => setOpen(false)}
                      className="d-flex position-relative custom-link align-items-center imgBlockHover py-2"
                    >
                      <div className="  me-2 justify-content-center align-content-center  d-flex">
                        <img
                          src={`${
                            theme === "dark" ? BlockchainACC2 : BlockchainACC
                          }`}
                          alt="BlockchainACC"
                          className="accountimages"
                        />
                      </div>
                      <div className="">BlockChain</div>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                {process.env.REACT_APP_API_URL !==
                "https://api.axiomwebcontrol.com/" ? (
                  <li
                    className={`px-4 ${
                      theme === "dark" ? "userList3" : "userList2"
                    }`}
                  >
                    <a
                      href="https://silocloud.com/wallet-dashboard/"
                      onClick={() => setOpen(false)}
                      className="d-flex position-relative custom-link align-items-center  imgBlockHover  py-2"
                    >
                      <div className="me-2 justify-content-center align-content-center d-flex">
                        <LuWallet2 className="accountimages" />
                      </div>
                      <div>Silo Wallet</div>
                    </a>
                  </li>
                ) : (
                  ""
                )}
                <li
                  className={`px-4 ${
                    theme === "dark" ? "userList3" : "userList2"
                  }`}
                >
                  <a
                    href={`${process.env.REACT_APP_ACCOUNT_URL}user-profile-regular`}
                    onClick={() => setOpen(false)}
                    className="d-flex position-relative custom-link align-items-center imgBlockHover py-2"
                  >
                    <div className="me-2 justify-content-center align-content-center d-flex">
                      <IoSettingsOutline className="accountimages" />
                    </div>
                    <div>Account Settings</div>
                  </a>
                </li>
                <li
                  className={`px-4 ${
                    theme === "dark" ? "userList3" : "userList2"
                  }`}
                >
                  <a
                    onClick={toggleTheme}
                    className="d-flex position-relative custom-link align-items-center  justify-content-between  py-2"
                  >
                    {theme === "dark" ? (
                      <div className="d-flex ">
                        <div className="me-2 justify-content-center align-content-center d-flex">
                          <FiSun
                            className="accountimages"
                            style={{ marginTop: "3px" }}
                          ></FiSun>
                        </div>
                        <div className="justify-content-center align-self-center d-flex">
                          Light Mode
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex ">
                        <div className="me-2 justify-content-center  align-content-center d-flex">
                          <GoMoon
                            className="accountimages"
                            style={{ marginTop: "3px" }}
                          ></GoMoon>
                        </div>
                        <div className="justify-content-center align-self-center d-flex">
                          Dark Mode
                        </div>
                      </div>
                    )}
                    <div className="">
                      <button
                        className="light-mode-button"
                        aria-label="Toggle Light Mode"
                        onClick={toggleTheme}
                      >
                        <span></span>
                        <span></span>
                      </button>
                    </div>
                  </a>
                </li>
              </div>

              <div className="px-4  border-top link-list ">
                <div onClick={setLogout} className="d-flex py-3  cursor">
                  <div
                    className={`justify-content-center align-content-center d-flex ${
                      theme === "dark" ? "usersignout2" : "usersignout"
                    }`}
                  >
                    <PiSignOut className="accountimages mt-1 " />
                    <div className=" ms-2 a imgBlockHover ">Sign Out</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
