import React from "react";
import { Helmet } from "react-helmet";

export default function Head({ ...props }) {
  return (
    <Helmet>
      <title>
        {" "}
        {props.title ? props.title + " | " : null}
        {process.env.REACT_APP_PROJECT_NAME}
      </title>
    </Helmet>
  );
}
