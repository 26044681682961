import { Link } from "react-router-dom";
import flipbook from "../Images/Silo_Home.png";
import flipbook1 from "../Images/Axiom_Home.png";
import { LuFolderClosed } from "react-icons/lu";
import { FaPlus } from "react-icons/fa6";
import React, { useState, useEffect } from "react";
import { getCookie } from "./Utils";
import { ShimmerTable } from "react-shimmer-effects";
import Cookies from "js-cookie";
export default function First() {
  const [folderData, setFolderData] = useState([]);
  const [isLoading, setIsLoading] = useState(true); 
  const [activeIndex, setActiveIndex] = useState(null);

  const handleFolderClick = (index) => {
    setActiveIndex(index);
  };

  // Collection and listing - GET API
  const fetchDatafolder = async () => {
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/flipbook/collection`,
        {
          method: "GET",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      setFolderData(responseData?.data?.collections);
      setIsLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false); // Set loading to false even if there's an error
    }
  };

  useEffect(() => {
    fetchDatafolder();
  }, []);

  let authToken = Cookies.get("authToken");

  return (
    <div className="container-fluid p-0">
      {authToken ? (
        <div className="w-100 m-0 p-0">
          {/* <div className="col-2 d-none d-md-block border-end p-0">
            <div className="sidebar p-3">
              {isLoading ? (
                <ShimmerTable row={6} col={1} />
              ) : (
                folderData &&
                folderData.map((item, idx) => (
                  <div
                    key={idx}
                    onClick={() => handleFolderClick(item.id)}
                    className={`sidebar-item ${
                      activeIndex === item.id ? "active" : ""
                    }`}
                  >
                    <LuFolderClosed
                      className={`sidebaricon ${
                        activeIndex === item.id ? "active-icon" : ""
                      }`}
                    />
                    <span
                      className={`sidebartext  ${
                        activeIndex === item.id ? "active-text" : ""
                      }`}
                    >
                      {item.collection_name}
                    </span>
                  </div>
                ))
              )}
            </div>
          </div> */}
          <div className="d-flex align-items-center justify-content-center home heightvh">
            <div className="p-4 text-center margin">
              <div className="d-flex justify-content-center py-2">
              {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
                <img
                  className="homeflipbookimg"
                  src={flipbook}
                  alt="Flipbook"
                />
              ) : (
                <img
                  className="homeflipbookimg"
                  src={flipbook1}
                  alt="Flipbook"
                  />
              )}
              </div>
              <div className="d-flex justify-content-center">
                <p className="heading">Let's start to create your flipbook</p>
              </div>
              {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
                <div className="d-flex justify-content-center">
                  <Link to="/dropdown">
                    <button
                      type="button"
                      className="btn btn-danger btnsize d-flex align-items-center"
                    >
                      <FaPlus className="btnlogo" />
                      <span className="btntext">Create</span>
                    </button>
                  </Link>
                </div>
              ) : (
                <div className="d-flex justify-content-center">
                  <Link to="/dropdown">
                    <button
                      type="button"
                      className="btn gradient-button btnsizeaxiom d-flex align-items-center"
                    >
                      <FaPlus className="btnlogo" />
                      <span className="btntext">Create</span>
                    </button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex align-items-center justify-content-center bg-white heightvh">
          <div className="text-center marginauthtoken">
            <div className="d-flex justify-content-center py-2">
              <img className="homeflipbookimg" src={flipbook} alt="Flipbook" />
            </div>
            <div className="d-flex justify-content-center">
              <p className="heading">Let's start to create your flipbook</p>
            </div>
            {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
              <div className="d-flex justify-content-center">
                <Link to="/dropdown">
                  <button
                    type="button"
                    className="btn btn-danger btnsize  d-flex align-items-center"
                  >
                    <FaPlus className="btnlogo" />
                    <span className="text-light btntext">Create</span>
                  </button>
                </Link>
              </div>
            ) : (
              <div className="d-flex justify-content-center">
                <Link to="/dropdown">
                  <button
                    type="button"
                    className="btn gradient-button  btnsizeaxiom d-flex align-items-center"
                  >
                    <FaPlus className="btnlogo" />
                    <span className="btntext ms-1">Create</span>
                  </button>
                </Link>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
