import React, { useState, useEffect } from "react";
import Apps from "./Apps";
import Notifi from "../Components/Notification";
import User from "../Components/User";
import { RxCross2, RxExitFullScreen } from "react-icons/rx";
import { IoMdMenu } from "react-icons/io";
import { GoScreenFull } from "react-icons/go";
import Calender from "../Images/Dark Calendar.png";
import Cookies from "js-cookie";
import { Link, Navigate, useNavigate } from "react-router-dom";
import publisherlight from "../Images/Publisherlight.png";
import publisherdark from "../Images/publisherdark.png";
import { useTheme } from "../Context/ThemeContext";
import { RiSearch2Line } from "react-icons/ri";
import { LuLoader2 } from "react-icons/lu";
import { fetchCollectionAndContent } from "./api/api";
import { useQuery } from "@tanstack/react-query";
import dp from "../Images/applogos/black_theme/dp.png";
const Header = () => {
  const [fullScreenOn, setFullScreenOn] = useState(false);
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [toggled, setToggled] = useState(false);
  const { theme, toggleTheme } = useTheme();

  const logoSrc = theme === "dark" ? publisherdark : publisherlight;

  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      // Enter fullscreen mode
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };

  let currentUrl;
  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const day = days[now.getDay()];
      const date = now.getDate();
      const month = now.getMonth() + 1;
      const year = now.getFullYear();

      setDate(`${day}, ${month}/${date}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);

  let authToken = Cookies.get("authToken");

  const handleLogin = () => {
    window.location = process.env.REACT_APP_ACCOUNT_URL;
  };

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(isSidebarOpen);
  };

  const [searchQuery, setSearchQuery] = useState("");
  const [searchParams, setSearchParams] = useState("");
  const [showSuggestions, setShowSuggestions] = useState(false);
  // Query with dynamic search parameters
  const { data, isLoading, isError } = useQuery({
    queryKey: ["collectionAndContent", searchParams],
    queryFn: () => fetchCollectionAndContent(searchParams),
    enabled: searchParams.length > 0, // Only fetch when searchParams is not empty
  });

  useEffect(() => {
    setSearchParams(new URLSearchParams({ search: searchQuery }).toString());
  }, [searchQuery]);

  const handleSearchInput = (event) => {
    setSearchQuery(event.target.value);
    setShowSuggestions(true);
  };
  const navigate = useNavigate();
  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    navigate(`/browse/${suggestion}`);
    setShowSuggestions(false);
  };

  const clearSearch = () => {
    setSearchQuery("");
    navigate("/browse");
  };

  const content = data?.data?.content || [];
  const creator = data?.data?.creator ? Object.values(data.data.creator) : [];

  return (
    <>
      <div className="header-section d-flex justify-content-between align-items-center px-4 h55 border-bottom flex-wrap">
        <div className="d-flex align-items-center">
          <a className="navbar-brand d-flex align-items-center" href="#">
            {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
              <Link to="https://publisher.silocloud.io/">
                <img
                  src={logoSrc}
                  // src={`${process.env.REACT_APP_API_URL}publisher/silo-publisher.png`}

                  alt=""
                  className="me-2 publisherlogo"
                />
              </Link>
            ) : (
              <Link to="https://publisher.axiomwebcontrol.com/">
                <img
                  src={`${process.env.REACT_APP_API_URL}publisher/flipbook.png`}
                  alt=""
                  className="me-2 publisherlogo"
                />
              </Link>
            )}

            {/* {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
              <div>
                <div className="brand-name">
                  {process.env.REACT_APP_PROJECT_NAME}
                </div>
                <div className="brand-subtitle">
                  by {process.env.REACT_APP_PROJECT_SHORT_NAME}
                </div>
              </div>
            ) : (
              ""
            )} */}
          </a>

          <div className="form-control-wrap pl-5 px-4 header-search d-none d-sm-block search-block">
            <span className="search-icon">
              <RiSearch2Line />
            </span>
            <input
              className="form-control searchinput"
              type="text"
              id="appsearch"
              placeholder="Find Content and Creator"
              autoFocus
              onChange={handleSearchInput}
              value={searchQuery}
              autoComplete="off"
              // onBlur={() => setSearchQuery("")}
            />
            <span className="clear-icon" onClick={clearSearch}>
              {isLoading ? (
                <LuLoader2 className="loading-icon" />
              ) : (
                <RxCross2 />
              )}
            </span>

            {searchQuery && showSuggestions && (
              <div className="suggestion-box">
                {content.length > 0 ? (
                  <div>
                    <ul>
                      {content.map((item, index) => (
                        <li
                          key={index}
                          onClick={() => handleSuggestionClick(item.title)}
                          className="d-flex align-items-center"
                        >
                          <div>
                            <img
                              src={item.thumbnail}
                              alt={item.title}
                              style={{ width: "50px", height: "50px" }}
                            />
                          </div>
                          <div className="ms-2 d-flex flex-column">
                            <span>{item.title}</span>
                            <small className="text-muted">
                              <span className="me-2 ">
                                <img
                                  src={item.profile_image || dp}
                                  alt={item.username}
                                  style={{
                                    height: "15px",
                                    width: "15px",
                                    borderRadius: "50%",
                                  }}
                                />
                              </span>
                              {item.username}
                            </small>
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div>
                    <ul>
                      <li className="d-flex align-items-center">
                        <p className="m-0">Search for "{searchQuery}"</p>
                      </li>
                    </ul>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>

        {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
          <>
            {" "}
            <div className="d-sm-flex d-none justify-content-center flex-grow-1 flex-shrink-1 my-2 my-md-0">
              <div className="d-flex flex-row align-items-center">
                <div className="lh-17px pe-2">
                  <div className="calendertime">{time}</div>
                  <div className="calenderdate">{date}</div>
                </div>
                <div>
                  <a href="https://calendar.silocloud.io/">
                    <img src={Calender} alt="" className="calendericon" />
                  </a>
                </div>
              </div>
            </div>
          </>
        ) : (
          ""
        )}

        <div className="d-flex  align-items-center text-decoration-none align-content-center">
          <div className="px-2 align-items-center">
            <IoMdMenu
              className="d-block d-md-none menu-btn fs-3 headericons"
              onClick={toggleSidebar}
            />
          </div>
          <div className="me-1 d-none d-sm-block">
            {fullScreenOn ? (
              <span
                className="material-symbols-outlined p-1 headericons"
                onClick={toggleFullScreen}
              >
                <RxExitFullScreen className=" fs-2" />
              </span>
            ) : (
              <span
                className="material-symbols-outlined p-1  headericons"
                onClick={toggleFullScreen}
              >
                <GoScreenFull className="p-1 fs-2" />
              </span>
            )}
          </div>

          <div>
            <Notifi />
          </div>
          {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
            <div>
              <Apps />
            </div>
          ) : (
            ""
          )}
          {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
            <div className="ps-2">
              {authToken ? (
                <User />
              ) : (
                <button
                  type="button"
                  class="btn btn-danger btnsize btntext"
                  onClick={handleLogin}
                >
                  Login
                </button>
              )}
            </div>
          ) : (
            <div className="ps-2">
              {authToken ? (
                <User />
              ) : (
                <button
                  type="button"
                  class="btn gradient-button btnsizeaxiom btntext"
                  onClick={handleLogin}
                >
                  Login
                </button>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Header;
