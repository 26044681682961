import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import Head from "./Components/Head";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
export default function Layout() {
  // const [authChecking, setAuthChecking] = useState(true);
  // useEffect(() => {
  //   let at = Cookies.get("authToken");
  //   if (!at) {
  //     const now = new Date();
  //     const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
  //     Cookies.set("currentURL", window.location.href, {
  //       domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //       expires: expires,
  //     });
  //     window.location = process.env.REACT_APP_ACCOUNT_URL;
  //   } else {
  //     setAuthChecking(false);
  //     Cookies.remove("currentURL", {
  //       domain: process.env.REACT_APP_COOKIE_DOMAIN,
  //     });
  //   }
  // }, []);
  // if (authChecking) return <>Redirecting to authentication</>;
  useEffect(() => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
    // console.log(process.env.REACT_APP_COOKIE_DOMAIN);
  }, []);
  return (
    <div>
       {process.env.REACT_APP_API_URL === "https://api.silocloud.io/" ? (
      <Head title={process.env.REACT_APP_TITLE} />
    ) : (
      ""
    )}

      <Header />
      <ToastContainer/>
      <Outlet />
      <Footer />
    </div>
  );
}
