import React, { useState } from "react";
import PublisheFlipbook from "./PublishFlipbook";
import { MdKeyboardBackspace } from "react-icons/md";
import { BsBook, BsCurrencyDollar, BsLink } from "react-icons/bs";
import { FaRegFileAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import Sell from "./Sell";
import Media from "./Media";
import Linking from "./Linking";

export default function SellPublishe() {
  const [activeSection, setActiveSection] = useState("publisheflipbook");
  const handleClick = (section) => {
    setActiveSection(section);
  };

  const renderContent = () => {
    switch (activeSection) {
      case "publisheflipbook":
        return <PublisheFlipbook />;
      case "selling":
        return <Sell />;
      case "linking":
        return <Linking />;
      case "media":
        return <Media />;
      default:
    }
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-1 d-none d-md-block sidebarpublish border-end">
          <Link to="/home">
            <div
              className={`sidebaritem1 py-3 ${
                activeSection === "landingpage" ? "active" : ""
              }`}
              onClick={() => handleClick("landingpag")}
            >
              <MdKeyboardBackspace className="sidebaricon1" />
              <span className="sidebartext1">Back</span>
            </div>
          </Link>

          <div
            className={`sidebaritem1 py-3 ${
              activeSection === "publisheflipbook" ? "active" : ""
            }`}
            onClick={() => handleClick("publisheflipbook")}
          >
            <BsBook className="sidebaricon1" />
            <span className="sidebartext1">Publication</span>
          </div>
          <div
            className={`sidebaritem1 py-3 ${
              activeSection === "selling" ? "active" : ""
            }`}
            onClick={() => handleClick("selling")}
          >
            <BsCurrencyDollar className="sidebaricon1" />
            <span className="sidebartext1">Selling</span>
          </div>
          {/* <div
            className={`sidebaritem1 py-3 ${
              activeSection === "linking" ? "active" : ""
            }`}
            onClick={() => handleClick("")}
          >
            <BsLink className="sidebaricon1" />
            <span className="sidebartext1">Linking</span>
          </div>
          <div
            className={`sidebaritem1 py-3 ${
              activeSection === "media" ? "active" : ""
            }`}
            onClick={() => handleClick("")}
          >
            <FaRegFileAlt className="sidebaricon1" />
            <span className="sidebartext1">Media</span>
          </div> */}
        </div>
        <div className="col-11 p-0">{renderContent()}</div>
      </div>
    </div>
  );
}
