import React, { useState } from "react";
import ThumbnailURL from "./ThumbnailUrl";
import { PDFDocument } from "pdf-lib";

export default function PreviewPages({ pdfurl }) {
  const [selectedPages, setSelectedPages] = useState([]);
  const pdfUrl = pdfurl;
  console.log(pdfurl);

  const handleSelectedPages = (pages) => {
    setSelectedPages(pages);
  };
  const handlePreview = async () => {
    if (selectedPages.length > 0) {
      const pdfBytes = await fetch(pdfUrl).then((res) => res.arrayBuffer());
      const originalPdf = await PDFDocument.load(pdfBytes);
      const newPdf = await PDFDocument.create();
      const copiedPages = await newPdf.copyPages(
        originalPdf,
        selectedPages.map((p) => p - 1)
      );
      copiedPages.forEach((page) => {
        newPdf.addPage(page);
      });

      const newPdfBytes = await newPdf.save();
      const blob = new Blob([newPdfBytes], { type: "application/pdf" });
      const url = URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "selected-pages.pdf";
      link.click();
      URL.revokeObjectURL(url);
    } else {
      alert("No pages selected!");
    }
  };
  return (
    <>
      <div className="container-fluid py-4 sellpx bg-white bgwheight">
        <h1>Preview pages</h1>
        <p>
          Choose which pages readers can see before purchasing your publication.
          your publication is currently marked as part of your preview.
        </p>
        {pdfurl && (
          <ThumbnailURL
            pdfUrl={pdfUrl}
            onSelectedPagesChange={handleSelectedPages}
          />
        )}

        {/* <div className="pt-5"> 
        <button type="button" class="btn btn-primary" disabled data-bs-toggle="button" autocomplete="off" onClick={handlePreview}>Preview Selected Pages</button>
        </div> */}
      </div>
    </>
  );
}
