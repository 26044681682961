
export const qrcode_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-qr.png`;
export const silo_marketplace_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-marketplace.png`;
export const silotalk_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-talk.png`;
export const storage_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-storage.png`;
export const streamdeck_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-streamdeck.png`;
export const support_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-support.png`;
export const tv_logo = `${process.env.REACT_APP_API_URL}logo/apps/tv-logo.png`;
export const Homelightlogo = `${process.env.REACT_APP_API_URL}logo/apps/home-logo.png`;
export const silogame = `${process.env.REACT_APP_API_URL}logo/apps/silo-games.png`;
export const store_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-store.png`;


export const img = `${process.env.REACT_APP_API_URL}logo/apps/background-image.png`;
export const calender_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-calender.png`;
export const mail_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-mail.png`;
export const community_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-community.png`;
export const assembler_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-assembler.png`;
export const pesonapost_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-post.png`;
export const pesonadigest_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-digest.png`;
export const pesonaos_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-os.png`;
export const pesonaradio_apps = `${process.env.REACT_APP_API_URL}logo/apps/persona-radio.png`;
export const erp_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-erp.png`;
export const suite_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-suite.png`;
export const constructor_logo = `${process.env.REACT_APP_API_URL}logo/apps/constructor-tool.png`;
export const canvas_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-canvas.png`;
export const map_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-maps.png`;
export const wallet_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-wallet.png`;
export const coin_exchange_logo = `${process.env.REACT_APP_API_URL}logo/apps/coin_exchange.png`;
export const merchant_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-merchant-white.png`;
export const merchant_logo_d = `${process.env.REACT_APP_API_URL}logo/apps/silo-merchant-dark.png`;
export const blockchain_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-blockchain.png`;
export const  bank_logo = `${process.env.REACT_APP_API_URL}logo/apps/silo-bank.png`;
export const sitebuilder_logo = `${process.env.REACT_APP_API_URL}logo/apps/site-builder.png`;
export const marketplace_apps = `${process.env.REACT_APP_API_URL}logo/apps/silo-marketplace.png`;
export const silo_viewer = `${process.env.REACT_APP_API_URL}logo/apps/silo-3d-viewer.png`;
export const silo_publisher = `${process.env.REACT_APP_API_URL}logo/apps/silo-publisher.png`;

export const d_plus_logo = require("./black_theme/plus_black.png");
export const d_home_logo = require("./black_theme/home_black.png");
export const home_logo = require("./black_theme/HomeLogo.png");