import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useQuery } from "@tanstack/react-query";
import { fetchCollectionAndContent } from "./api/api";
import { useParams } from "react-router-dom";

const SkeletonPosts = () => {
  const skeletonArray = Array(6).fill(0);

  return (
    <div className="row">
      {skeletonArray.map((_, index) => (
        <div key={index} className="col-md-2 col-sm-4 col-xs-6 mb-2">
          <Skeleton className="postimage" />
          <div className="postText">
            <Skeleton />
            <Skeleton className="published-text" />
            <Skeleton className="published-text" />
          </div>
        </div>
      ))}
    </div>
  );
};

export default function Browse() {
  const [safeMode, setSafeMode] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const { publisher_name } = useParams();

  const searchParams = new URLSearchParams();
  if (publisher_name) {
    searchParams.set("search", publisher_name);
  }

  const { data, isLoading, isError } = useQuery({
    queryKey: ["publication", publisher_name],
    queryFn: () => fetchCollectionAndContent(searchParams.toString()),
    enabled: true,
  });

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const toggleSafeMode = () => {
    setSafeMode(!safeMode);
  };

  const content = data?.data?.content || [];

  return (
    <>
      <div className="container-fluid d-flex justify-content-between py-3">
        <div className="search-resultsc">
          {publisher_name && (
            <span>
              Results for <b>"{decodeURIComponent(publisher_name)}"</b>
            </span>
          )}
        </div>
        <div className="filters">
          <div className="dropdown2">
            <div className="dropdownheader" onClick={toggleDropdown}>
              <div className="sidebar-text">Any time</div>
              <div className="dropdownarrow">
                {isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </div>
            </div>
            {isOpen && (
              <div className="dropdowncontent">
                <div className="dropdowndata">
                  <div className="dropdownitem">
                    <span className="sidebartext">Last 7 days</span>
                  </div>
                  <div className="dropdownitem">
                    <span className="sidebartext">Last 30 days</span>
                  </div>
                  <div className="dropdownitem">
                    <span className="sidebartext">Last 90 days</span>
                  </div>
                  <div className="dropdownitem">
                    <span className="sidebartext">Last year</span>
                  </div>
                  <div className="dropdownitem">
                    <span className="sidebartext">Last 5 years</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="safe-mode-toggle">
            <span>Safe mode</span>
            <div
              className={`toggle-switch ${safeMode ? "active" : ""}`}
              onClick={toggleSafeMode}
            >
              <div className="toggle-circle"></div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid py-3">
        {isLoading ? (
          <SkeletonPosts />
        ) : (
          <div className="row">
            {content.map((contents, index) => (
              <div className="col-md-2 col-sm-4 col-xs-6 mb-2" key={index}>
                <img src={contents.thumbnail} alt="" className="postimage" />
                <div className="postText">
                  <span>{contents.title}</span>
                  <br />
                  <span className="published-text">
                    {contents.publish_date}
                  </span>
                  <br />
                  <span className="published-text">
                    by <a className="author-link">{contents.username}</a>
                  </span>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}
